import { useEffect, useState } from 'react';
import { Warning } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  LinearProgress,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tooltip,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useCreateShipment } from '@/api/shipping';
import Can from '@/components/Permissions/Can';
import ClosableDrawer from '@/components/Shared/ClosableDrawer';
import ChooseRates from '@/components/Shipping/ChooseRates';
import QuoteShipmentForm from '@/components/Shipping/QuoteShipmentForm';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs } from '@/contexts/DialogContext';
import { useLabelType, usePrintShipmentLabel } from '@/contexts/PrintContext';
import useMarkAsShipped from '@/hooks/useMarkAsShipped';
import useThirdPartyAccountsForCustomer from '@/hooks/useThirdPartyAccountsForCustomer';
import {
  Address,
  Shipment,
  ShipmentBasePayload,
  ShipmentQuotePayload,
  ShippingDefaultsResponse,
  ShippingMode,
} from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import { isInventory } from '@/utils/shipping';
import { transformLaravelErrors } from '@/utils/validator';

function getIsPickup(method: string | null | undefined) {
  const methodString = (method || '').toLowerCase();
  return methodString.includes('pickup') || methodString === 'delivery by sales';
}

export default function ShipActions({
  payload,
  customerId,
  title,
  qty,
  onSuccess,
  address,
  shipButtonProps = { children: 'Ship' },
  size = 'medium',
  hideMarkAsShipped,
  shippingMode = 'bulk',
}: {
  payload: ShipmentBasePayload;
  customerId: number | undefined;
  title: string;
  qty: number;
  onSuccess: (s: Shipment) => void;
  address?: Address | null;
  shipButtonProps?: ButtonProps;
  size?: 'small' | 'medium';
  hideMarkAsShipped?: boolean;
  shippingMode?: ShippingMode;
}) {
  const [shipping, setShipping] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [step, setStep] = useState(0);
  const [values, setValues] = useState<ShipmentQuotePayload>();
  const labelType = useLabelType();
  const printLabelRequest = usePrintShipmentLabel();
  const thirdPartyAccounts = useThirdPartyAccountsForCustomer(customerId);
  const createShipmentRequest = useCreateShipment();
  const addressPrintableId = useConfig().printables.find((p) => p.model === 'address')?.id;
  const { confirm } = useDialogs();

  const defaultsRequest = useMutation((payload: ShipmentBasePayload) =>
    axios
      .post<ShippingDefaultsResponse>(`/api/shipments/defaults`, payload)
      .then(({ data }) => data),
  );

  useEffect(() => {
    if (shipping) {
      setStep(0);
      defaultsRequest.mutateAsync(payload).then((defaults) => {
        setValues({
          ...payload,
          from_address_id: defaults.from_address.id,
          require_signature: defaults.require_signature,
          service_level: defaults.service_level,
          third_party_account_id: defaults.third_party_account_id,
          reference_1: defaults.reference_1,
          reference_2: defaults.reference_2,
          customs: defaults.customs,
          parcels: defaults.parcels,
        });
      });
    }
  }, [shipping, JSON.stringify(payload)]);

  const getQuotes = () => {
    setErrors({});
    setStep(1);
  };

  const { markAsShipped } = useMarkAsShipped({
    title,
    onSuccess,
    shippingMode,
  });

  const onRatesError = (e: AxiosError) => {
    const res = e.response?.data;
    if (res && typeof res === 'object' && 'errors' in res) {
      setErrors(transformLaravelErrors((res.errors || {}) as Record<string, string[]>));
    }
    setStep(0);
  };

  const onChooseRate = (rateId: string) => {
    createShipmentRequest
      .mutateAsync({
        ...payload,
        rate_id: rateId,
        label_type: labelType,
      })
      .then((shipment) => {
        onSuccess(shipment);
        setShipping(false);
        printLabelRequest.mutate(shipment.id);
      });
  };

  const onPrintCustomerLabel = () => {
    confirm({
      title: 'Customer Box Label Printed?',
      description:
        "Please confirm you printed the customer's box label and attached it to each box.",
    }).then(() => {
      axios.post(`/api/addresses/${address?.id}/customer-label-printed`);
    });
  };

  const isPickup = getIsPickup(address?.method);

  if (isInventory(shippingMode)) {
    return (
      <Can permission="write:shipments">
        <Button
          size={size}
          onClick={() => markAsShipped(payload, qty)}
          sx={{ mr: 1, whiteSpace: 'nowrap' }}
          variant="contained"
        >
          Add to Inventory
        </Button>
      </Can>
    );
  }

  return (
    <Box display="inline-flex" alignItems="center">
      {address?.customer_label && (
        <Tooltip title="The customer has requested an additional label be printed and affixed to each box for this order.">
          <IconButton
            component="a"
            target="_blank"
            href={getApiUrl(`/api/printables/${addressPrintableId}/render/${address.id}`)}
            onClick={onPrintCustomerLabel}
          >
            <Warning color="warning" />
          </IconButton>
        </Tooltip>
      )}

      {(isPickup || !hideMarkAsShipped) && payload.address_id && (
        <Can permission="write:shipments">
          <Button
            size={size}
            onClick={() => markAsShipped(payload, qty)}
            sx={{ mr: 1, whiteSpace: 'nowrap' }}
          >
            Mark Shipped
          </Button>
        </Can>
      )}

      {!isPickup && (
        <Can permission="shipments:purchase">
          <Button
            size={size}
            onClick={() => setShipping(true)}
            variant="contained"
            {...shipButtonProps}
          />
        </Can>
      )}

      <ClosableDrawer open={shipping} onClose={() => setShipping(false)} title={title}>
        <Stepper activeStep={step} sx={{ mt: -2, mb: 2 }}>
          <Step>
            <StepButton onClick={() => setStep(0)}>Shipment Info</StepButton>
          </Step>
          <Step>
            <StepLabel>Choose Rate</StepLabel>
          </Step>
        </Stepper>

        {!defaultsRequest.data || !values ? (
          <LinearProgress />
        ) : (
          <>
            {step === 0 && (
              <QuoteShipmentForm
                defaults={defaultsRequest.data}
                values={values}
                setValues={setValues}
                onSubmit={getQuotes}
                errors={errors}
                thirdPartyAccounts={thirdPartyAccounts}
              />
            )}

            {step === 1 && (
              <ChooseRates
                address={defaultsRequest.data.address}
                values={values}
                onChoose={onChooseRate}
                isChoosing={createShipmentRequest.isLoading}
                onError={onRatesError}
              />
            )}
          </>
        )}
      </ClosableDrawer>
    </Box>
  );
}
