import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Check, PlaylistAddCheck, Search, Undo } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Chip,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import groupBy from 'lodash/groupBy';
import inRange from 'lodash/inRange';
import startCase from 'lodash/startCase';
import { useGetKitsForOrder, usePerformKitAction } from '@/api/kitting';
import AddressTitle from '@/components/Addresses/AddressTitle';
import BatchShippingDrawer from '@/components/BatchShipping/BatchShippingDrawer';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import KitLabel from '@/components/Kitting/KitLabel';
import PrintMenu from '@/components/Print/PrintMenu';
import SkuLabel from '@/components/Products/SkuLabel';
import DebouncedTextField from '@/components/Shared/DebouncedTextField';
import StatusChip from '@/components/Shared/StatusChip';
import ShipActions from '@/components/Shipping/ShipActions';
import { PICK_STATUS_COLORS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import useScanDetection from '@/hooks/useScanDetection';
import { useSkipper } from '@/hooks/useSkipper';
import { Address, KitWithAddress, ShipmentItemPayload, ShippingMode } from '@/types';
import { formatTimestamp } from '@/utils/dates';
import { buildIncrementId } from '@/utils/notes';
import numString from '@/utils/numString';
import searchCollection from '@/utils/searchCollection';

const columnHelper = createColumnHelper<KitWithAddress>();

const toShip = (kit: KitWithAddress) => kit.pick_status != 'complete';

export default function KitsTable({
  orderId,
  customerId,
  forInventory,
}: {
  orderId: number;
  customerId: number;
  forInventory?: boolean;
}) {
  const [selected, setSelected] = useState<ShipmentItemPayload[]>([]);
  const [selectingForAddress, setAddress] = useState<Address>();
  const addressId = selectingForAddress?.id;
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [tab, setTab] = useState<'all' | 'toship' | 'shipped'>('all');
  const [isBatchShipping, setIsBatchShipping] = useState(false);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const { confirm } = useDialogs();

  const [autoResetPageIndex, skipResetPageIndex] = useSkipper();
  const { data: kits = [], refetch, isFetching } = useGetKitsForOrder(orderId);
  const performKitAction = usePerformKitAction(orderId, skipResetPageIndex);
  const shippingMode: ShippingMode = forInventory ? 'inventory_kit' : 'inventory';

  useScanDetection({
    onComplete: (scan) => {
      setQuery(scan);
      setTimeout(() => {
        searchRef.current?.blur();
      }, 500);
    },
  });

  const handleKitAction = (kit: KitWithAddress, action: 'start' | 'complete' | 'reset') => {
    confirm({
      title: `${startCase(action)} Kit: ${kit.number || kit.name}`,
      description: 'Are you sure you want to take this action?',
    }).then(() => {
      performKitAction.mutate({ id: kit.id, action });
    });
  };

  const handleCheck = useCallback(
    (kit: KitWithAddress) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        if (kit.address.id === addressId) {
          setSelected((prev) => [
            ...prev,
            {
              kit_id: kit.id,
              qty_shipped: 1,
            },
          ]);
        } else {
          setAddress(kit.address);
          setSelected([
            {
              kit_id: kit.id,
              qty_shipped: 1,
            },
          ]);
        }
      } else {
        setSelected(selected.filter((s) => s.kit_id !== kit.id));
      }
    },
    [setAddress, setSelected, addressId],
  );

  const handleCheckAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>, kits: KitWithAddress[]) => {
      const address = kits[0].address;

      setSelected((prev) => {
        if (addressId === address.id && prev.length > 0) {
          return [];
        }
        return kits.filter(toShip).map((k) => ({
          kit_id: k.id,
          qty_shipped: 1,
        }));
      });
      setAddress(address);
    },
    [setSelected, setAddress, addressId],
  );
  const onShipSuccess = useCallback(() => {
    setSelected([]);
    setAddress(undefined);
  }, [setSelected, setAddress]);

  const [rows, multipleKitsPerAddress] = useMemo(() => {
    const rows = searchCollection(
      kits.filter((k) => {
        if (tab === 'toship') {
          return toShip(k);
        }
        if (tab === 'shipped') {
          return !toShip(k);
        }
        return true;
      }),
      query,
      ['id', 'number', 'name', 'address.name', 'bin_string'],
    );
    const multipleKitsPerAddress = Object.values(groupBy(rows, 'address.id')).some(
      (r) => r.length > 1,
    );

    return [rows, multipleKitsPerAddress];
  }, [kits, query, tab]);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'checkbox',
        enableHiding: false,
        aggregatedCell: ({ row }) =>
          row.subRows.some((r) => toShip(r.original)) && (
            <Checkbox
              onChange={(e) =>
                handleCheckAll(
                  e,
                  row.subRows.map((r) => r.original),
                )
              }
              checked={row.subRows.every((r) => selected.some((s) => s.kit_id === r.original.id))}
              indeterminate={
                selected.length != row.subRows.length &&
                row.subRows.some((r) => selected.some((s) => s.kit_id === r.original.id))
              }
            />
          ),
        cell: ({ row: { original: kit } }) =>
          toShip(kit) && (
            <Checkbox
              onChange={handleCheck(kit)}
              checked={selected.some((s) => s.kit_id === kit.id)}
            />
          ),
      }),
      columnHelper.accessor('address.id', {
        header: forInventory ? 'Kit SKU' : 'Address',
        size: 320,
        cell: ({
          row: {
            original: { address },
          },
        }) =>
          forInventory && address.inventory_variant ? (
            <SkuLabel variant={address.inventory_variant} />
          ) : (
            <AddressTitle address={address} />
          ),
      }),
      columnHelper.accessor('address.bin_number', {
        header: 'Address Bin',
        aggregatedCell: ({ getValue, row }) =>
          getValue() && <Chip label={row.subRows[0].original.address.bin_string} />,
        cell: ({ row }) => <Chip label={row.original.address.bin_string} />,
      }),
      columnHelper.accessor('bin_number', {
        header: 'Bin',
        enableHiding: false,
        aggregationFn: 'min',
        aggregatedCell: () => null,
        cell: ({ row }) => <KitLabel kit={row.original} />,
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        aggregatedCell: () => null,
      }),
      columnHelper.accessor('number', {
        header: 'Number',
        aggregatedCell: () => null,
      }),
      columnHelper.accessor('group', {
        header: 'Group',
        aggregatedCell: () => null,
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        aggregatedCell: () => null,
      }),
      columnHelper.accessor('pick_status', {
        header: 'Status',
        aggregatedCell: () => null,
        cell: ({ getValue }) => (
          <StatusChip status={getValue()} colors={PICK_STATUS_COLORS} size="small" />
        ),
      }),
      columnHelper.accessor('pick_started_at', {
        header: 'Picked At',
        aggregatedCell: () => null,
        cell: ({ getValue }) => formatTimestamp(getValue()),
      }),
      columnHelper.accessor('pick_completed_at', {
        header: 'Packed At',
        aggregatedCell: () => null,
        cell: ({ getValue }) => formatTimestamp(getValue()),
      }),
      columnHelper.display({
        id: 'actions',
        enableHiding: false,
        cell: ({ row: { original: kit } }) => (
          <Box display="flex" alignItems="center" gap={0.75}>
            <PrintMenu model="kit" id={kit.id} size="small" />

            <Tooltip title="Reset Pick">
              <IconButton onClick={() => handleKitAction(kit, 'reset')} size="small">
                <Undo fontSize="small" />
              </IconButton>
            </Tooltip>

            {kit.pick_status === 'issued' ? (
              <Tooltip title="Start Picking">
                <IconButton onClick={() => handleKitAction(kit, 'start')} size="small">
                  <Check fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : kit.pick_status === 'picking' ? (
              <Tooltip title="Start Packing">
                <IconButton onClick={() => handleKitAction(kit, 'complete')} size="small">
                  <Check fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : kit.pick_status === 'pending_shipment' ? (
              <ShipActions
                size="small"
                payload={{
                  shippable_type: 'order',
                  shippable_id: orderId,
                  address_id: kit.address.id,
                  items: [{ kit_id: kit.id, qty_shipped: 1 }],
                }}
                customerId={customerId}
                title={`Shipping ${kit.number || `Kit #${kit.id}`}`}
                qty={1}
                hideMarkAsShipped
                onSuccess={onShipSuccess}
                shippingMode={shippingMode}
              />
            ) : null}
          </Box>
        ),
      }),
    ],
    [forInventory, orderId, customerId, handleCheck, handleCheckAll, onShipSuccess, selected],
  );

  return (
    <Card>
      <CardHeader
        title="Kits to Ship"
        action={
          <div>
            {selectingForAddress && selected.length > 0 ? (
              <ShipActions
                payload={{
                  shippable_type: 'order',
                  shippable_id: orderId,
                  address_id: selectingForAddress.id,
                  items: selected,
                }}
                customerId={customerId}
                title={`Shipping ${numString(selected.length, 'Kits')}`}
                qty={selected.length}
                onSuccess={onShipSuccess}
                address={selectingForAddress}
                shippingMode={shippingMode}
              />
            ) : (
              <>
                {!searching && !query ? (
                  <IconButton onClick={() => setSearching(true)} size="large">
                    <Search />
                  </IconButton>
                ) : (
                  <DebouncedTextField
                    ref={searchRef}
                    initialValue={query}
                    onChange={setQuery}
                    onBlur={() => setSearching(false)}
                    autoFocus
                    label="Search"
                    size="small"
                    type="search"
                  />
                )}

                {kits.length > 200 && (
                  <Tooltip title="Batch Ship">
                    <IconButton onClick={() => setIsBatchShipping(true)} size="large">
                      <PlaylistAddCheck />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        }
      />

      <Tabs onChange={(e, t) => setTab(t)} value={tab}>
        <Tab value="all" label="All" />
        <Tab value="toship" label="To Ship" />
        <Tab value="shipped" label="Shipped" />
      </Tabs>

      {query &&
        rows.length === 1 &&
        inRange(kits.filter((k) => k.address.id === rows[0].address.id).length, 2, 5) && (
          <Alert
            severity="info"
            action={<Button onClick={() => setQuery(rows[0].address.name)}>See All</Button>}
          >
            There are other kits shipping to this address.
          </Alert>
        )}

      <PaginatedTable
        storageKey={`kits-${orderId}-${multipleKitsPerAddress}`}
        definedGrouping={['address_id']}
        initialIsGrouped={multipleKitsPerAddress}
        rows={rows}
        refetch={refetch}
        isFetching={isFetching}
        autoResetPageIndex={autoResetPageIndex}
        enableGlobalFilter={false}
        initialState={{
          sorting: [{ id: 'bin_number', desc: false }],
          columnVisibility: {
            group: false,
            email: false,
            pick_started_at: false,
            pick_completed_at: false,
            address_bin_number: false,
          },
        }}
        filename={`kits-${buildIncrementId('SO', orderId).toLowerCase()}`}
        columns={columns}
      />

      <BatchShippingDrawer
        open={isBatchShipping}
        onClose={() => setIsBatchShipping(false)}
        orderId={orderId}
        customerId={customerId}
      />
    </Card>
  );
}
