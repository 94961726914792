import sumBy from 'lodash/sumBy';
import OrderShipping from '@/components/Shipping/OrderShipping';
import ShipActions from '@/components/Shipping/ShipActions';
import { Order } from '@/types';
import numString from '@/utils/numString';

export default function OrderShippingInventory({ order }: { order: Order }) {
  return (
    <OrderShipping
      orderId={order.id}
      getActions={({ selected, onSuccess }) => {
        const qty = sumBy(selected, 'qty_shipped');
        return (
          <ShipActions
            payload={{
              shippable_type: 'order',
              shippable_id: order.id,
              items: selected,
            }}
            customerId={order.customer_id}
            title={`Add ${numString(qty, 'Items')} to Inventory`}
            qty={qty}
            onSuccess={onSuccess}
            shippingMode={order.shipping_mode}
          />
        );
      }}
    />
  );
}
