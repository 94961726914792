import { ArrowForward } from '@mui/icons-material';
import { Card, CardHeader, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import StatusChip from '@/components/Shared/StatusChip';
import { WEBSTORE_ORDER_STATUS_COLORS } from '@/constants';
import { FulfillmentOrder } from '@/types';
import { formatDate } from '@/utils/dates';

export default function FulfillmentOrderCard({ order }: { order: FulfillmentOrder }) {
  return (
    <Card>
      <CardHeader
        title={order.number}
        action={
          <IconButton component={Link} to={`/fulfillment-orders/${order.id}`}>
            <ArrowForward />
          </IconButton>
        }
      />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">Customer</TableCell>
            <TableCell>{order.customer?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Webstore</TableCell>
            <TableCell>{order.webstore_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Status</TableCell>
            <TableCell>
              <StatusChip status={order.status} colors={WEBSTORE_ORDER_STATUS_COLORS} />
            </TableCell>
          </TableRow>
          {order.earliest_ship_date && (
            <TableRow>
              <TableCell variant="head">Earliest Ship Date</TableCell>
              <TableCell>{formatDate(order.earliest_ship_date)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}
