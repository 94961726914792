import { useState } from 'react';
import { Add, Delete, Edit, ExpandMore, Refresh } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment/moment';
import { z } from 'zod';
import { CardLayout, FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import PurchaseOrderTrackingItems from '@/components/Purchasing/PurchaseOrderTrackingItems';
import PurchaseOrderTrackings from '@/components/Purchasing/PurchaseOrderTrackings';
import ConditionallyRenderField from '@/components/Shared/ConditionallyRenderField';
import StatusChip from '@/components/Shared/StatusChip';
import { PO_STATUS_ID_COLORS, PO_STATUS_IDS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { PurchaseOrder, PurchaseOrderStatus, purchaseOrderStatusPayloadSchema } from '@/types';
import '@/types';

export default function PurchaseOrderStatuses({ purchaseOrder }: { purchaseOrder: PurchaseOrder }) {
  const { confirm, drawerPrompt } = useDialogs();
  const [statusOpen, setStatusOpen] = useState<number>();
  const poId = purchaseOrder.id;
  const queryClient = useQueryClient();
  const { data: statuses, isFetching } = useQuery(['poStatuses', poId], () =>
    axios
      .get<{ data: PurchaseOrderStatus[] }>(`/api/purchase-orders/${poId}/statuses`)
      .then(({ data }) => data.data),
  );

  const setStatuses = (r: { data: PurchaseOrderStatus[] }) =>
    queryClient.setQueryData(['poStatuses', poId], r.data);

  const onStatus = (updating?: PurchaseOrderStatus) => {
    drawerPrompt<z.infer<typeof purchaseOrderStatusPayloadSchema>, { data: PurchaseOrderStatus[] }>(
      {
        title: updating ? 'Update Status' : 'Add Status',
        fields: [
          FieldFactory.text('factory_order_number').withLabel('Vendor Order Number'),
          FieldFactory.select('status_id', PO_STATUS_IDS).withLabel('Status'),
          new ConditionallyRenderField(
            'expected_ship_date',
            FieldFactory.date('expected_ship_date'),
            (f) => Number(f.status_id) < 80,
          ),
          new CardLayout('trackings')
            .withFields([
              FieldFactory.list('trackings', [
                FieldFactory.text('tracking_number').withColumnSpan(6),
                FieldFactory.autocomplete('carrier', ['fedex', 'ups', 'usps']),
              ]).with({
                addNewLabel: 'Add Tracking',
              }),
            ])
            .withoutDefaultLayout(),
          new CardLayout('items')
            .withFields([
              FieldFactory.list('items', [
                FieldFactory.text('mpn').withColumnSpan(6),
                FieldFactory.number('qty'),
              ]).with({
                addNewLabel: 'Add Item',
              }),
            ])
            .withoutDefaultLayout(),
        ],
        schema: purchaseOrderStatusPayloadSchema,
        initialValues: updating || {
          factory_order_number: '',
          status_id: 10,
          expected_ship_date: null,
          items: [],
          trackings: [],
        },
        onSubmit: (v) =>
          axios
            .post<{ data: PurchaseOrderStatus[] }>(`/api/purchase-orders/${poId}/statuses`, v)
            .then(({ data }) => data),
      },
    ).then((data) => {
      setStatuses(data);
    });
  };

  const onDelete = (statusId: number) => {
    confirm({ title: 'Delete Tracking Number', description: 'Are you sure?' }).then(() => {
      axios
        .delete(`/api/purchase-orders/${poId}/statuses/${statusId}`)
        .then(({ data }) => setStatuses(data));
    });
  };

  const { mutate: onRefresh, isLoading: isRefreshing } = useMutation(
    () =>
      axios
        .put<{ data: PurchaseOrderStatus[] }>(`/api/purchase-orders/${poId}/statuses`)
        .then(({ data }) => data),
    {
      onSuccess: setStatuses,
    },
  );

  return (
    <>
      <CardHeader
        title={`Status: ${purchaseOrder.increment_id}`}
        subheader={purchaseOrder.vendor.name}
        action={
          <Can permission="write:purchase_orders">
            <Tooltip title="Add Status">
              <IconButton onClick={() => onStatus()}>
                <Add />
              </IconButton>
            </Tooltip>
            {purchaseOrder.has_order_status_integration && (
              <Tooltip title="Update Status from Vendor">
                <IconButton onClick={() => onRefresh()}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </Can>
        }
      />

      {(isFetching || isRefreshing) && <LinearProgress />}

      {statuses &&
        statuses.map((status) => (
          <Accordion
            key={status.id}
            expanded={statusOpen === status.id}
            onChange={() => setStatusOpen((prev) => (prev === status.id ? undefined : status.id))}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ flexGrow: 1, fontWeight: 500 }}>
                #{status.factory_order_number}
              </Typography>
              <StatusChip
                status={String(status.status_id)}
                colors={PO_STATUS_ID_COLORS}
                size="small"
                sx={{ mr: 1 }}
              >
                {status.status}
              </StatusChip>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, py: 0 }}>
              <Stack spacing={2}>
                {status.status_id !== 80 && status.expected_ship_date && (
                  <Typography sx={{ px: 2 }}>
                    Expected to ship on <b>{moment(status.expected_ship_date).format('l')}</b>
                  </Typography>
                )}

                {status.trackings.length > 0 ? (
                  <div>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ px: 2 }}>
                      Tracking Numbers
                    </Typography>
                    <PurchaseOrderTrackings trackings={status.trackings} />
                  </div>
                ) : (
                  <Typography variant="subtitle2" color="textSecondary" sx={{ px: 2 }}>
                    No tracking numbers
                  </Typography>
                )}

                {status.items.length > 0 && (
                  <div>
                    <Typography variant="subtitle2" color="textSecondary" sx={{ px: 2 }}>
                      Items
                    </Typography>
                    <PurchaseOrderTrackingItems items={status.items} />
                  </div>
                )}
              </Stack>

              <CardActions>
                <IconButton onClick={() => onStatus(status)}>
                  <Edit fontSize="small" />
                </IconButton>
                <IconButton onClick={() => onDelete(status.id)}>
                  <Delete fontSize="small" />
                </IconButton>
              </CardActions>
            </AccordionDetails>
          </Accordion>
        ))}

      {statuses && statuses.length === 0 && (
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            There are no statuses for this PO.
          </Typography>
        </CardContent>
      )}
    </>
  );
}
