import { ReactNode } from 'react';
import { OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import startCase from 'lodash/startCase';
import ProductionDesignColorsDialog from '@/components/Dialogs/ProductionDesignColorsDialog';
import { OrderDesign } from '@/types';
import getApiUrl from '@/utils/getApiUrl';
import { isScreenPrint } from '@/utils/helpers';
import numString from '@/utils/numString';
import ComplexityLabel from './ComplexityLabel';
import DesignColorsTable from './DesignColorsTable';

function Label({ children }: { children: ReactNode }) {
  return (
    <TableCell variant="head" sx={{ verticalAlign: 'top' }}>
      <Box color="text.secondary" whiteSpace="nowrap">
        {children}
      </Box>
    </TableCell>
  );
}

export default function OrderDesignCard({
  orderDesign,
  sx = {},
}: {
  orderDesign: OrderDesign;
  sx?: SxProps;
}) {
  const {
    design,
    design_layout_id: designLayoutId,
    ink_changes: inkChanges,
    proofs,
    colors,
    attachments,
  } = orderDesign;

  return (
    <Card sx={sx}>
      <CardHeader
        avatar={design.image && <Avatar src={design.image} />}
        title={<Typography variant="h5">{design.increment_id}</Typography>}
        subheader={
          <Typography variant="subtitle1" color="textSecondary">
            {design.name}
          </Typography>
        }
        action={
          <Tooltip title="Go To Design">
            <IconButton component="a" target="_blank" href={`/designs/${design.id}`} size="large">
              <OpenInNew fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      />
      {inkChanges.length > 0 && (
        <CardContent>
          <Alert severity="info">
            This design contains {numString(inkChanges.length, 'ink changes')}. Therefore, the above
            design thumbnail might contain the wrong colors.
          </Alert>
        </CardContent>
      )}
      <Table size="small">
        <TableBody>
          {proofs.map((proof) => (
            <TableRow key={proof.id}>
              <Label>{startCase(proof.type)} Proof</Label>
              <TableCell>
                <a href={proof.pdf || (proof.images?.[0] ?? '')}>
                  <img
                    src={proof.images?.[0] ?? ''}
                    alt={proof.file_name}
                    style={{
                      width: '100%',
                      maxHeight: 400,
                      objectFit: 'contain',
                    }}
                  />
                </a>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <Label>Decoration Type</Label>
            <TableCell>{design.decoration_type.name}</TableCell>
          </TableRow>
          <TableRow>
            <Label>
              <ComplexityLabel design={design} />
            </Label>
            <TableCell>{design.complexity}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Design Code</Label>
            <TableCell>{design.design_code}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Height</Label>
            <TableCell>{design.height}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Width</Label>
            <TableCell>{design.width}</TableCell>
          </TableRow>
          <TableRow>
            <Label>Files</Label>
            <TableCell component="th">
              {attachments.map((a) => (
                <div key={a.id}>
                  <a href={a.url} target="_blank" rel="noreferrer">
                    {a.name}
                  </a>
                </div>
              ))}
            </TableCell>
          </TableRow>
          {design.decoration_type.has_drops && designLayoutId ? (
            <TableRow>
              <Label>Roster</Label>
              <TableCell component="th">
                <a
                  href={getApiUrl(`/api/design-layouts/${designLayoutId}/roster`)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          Colors
        </Typography>
        {isScreenPrint(design.decoration_type?.unit) && (
          <ProductionDesignColorsDialog design={design} />
        )}
      </CardContent>
      <DesignColorsTable
        colors={colors}
        hasUnderbase={design.has_underbase}
        decorationUnit={design.decoration_type?.unit}
      />
    </Card>
  );
}
