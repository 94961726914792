import { DashboardTwoTone, Inventory } from '@mui/icons-material';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import TagIcon from '@mui/icons-material/LocalOffer';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InboxIcon from '@mui/icons-material/MarkunreadMailbox';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/SettingsApplications';
import CartIcon from '@mui/icons-material/ShoppingCart';
import CustomerUpdate from '@/pages/CustomerUpdate';
import GenericResource from '@/pages/GenericResource';
import { getAssociatedLinkForEntry } from '@/resources/ledgerEntries';
import { NavItem, Page } from '@/resources/types';
import {
  DesignLayout,
  LedgerEntry,
  Order,
  ProductionEvent,
  PurchaseOrderItem,
  Shipment,
} from '@/types';
import Payouts from './components/Payouts/Payouts';
import AccountingReports from './pages/AccountingReports';
import Banking from './pages/Banking';
import CollectPayment from './pages/CollectPayment';
import Config from './pages/Config';
import Home from './pages/Home';
import MyBusiness from './pages/MyBusiness';
import PricingEnginePage from './pages/PricingEnginePage';
import PrintChecks from './pages/PrintChecks';
import ProductSearch from './pages/ProductSearch';
import ProductionForecast from './pages/ProductionForecast';
import ProductionSchedule from './pages/ProductionSchedule';
import Receivables from './pages/Receivables';
import Reconciliation from './pages/Reconciliation';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import SetupGmail from './pages/SetupGmail';
import TransactionImporter from './pages/TransactionImporter';

export const direct: Page[] = [
  {
    name: 'Generic Configuration',
    component: Config,
    href: '/config',
    group: 'General',
    requiresPermission: 'write:config',
  },
  {
    name: 'Users',
    component: GenericResource,
    href: '/users',
    resource: 'users',
    group: 'Users',
    requiresPermission: 'write:users',
  },
  {
    name: 'Setup Gmail',
    component: SetupGmail,
    href: '/gmail-setup',
    group: 'Users',
  },
  {
    name: 'Lines of Business',
    component: GenericResource,
    href: '/businesses',
    resource: 'businesses',
    group: 'General',
    requiresPermission: 'read:businesses',
  },
  {
    name: 'Order Types',
    component: GenericResource,
    href: '/order-types',
    resource: 'orderTypes',
    group: 'Customers & Orders',
    requiresPermission: 'write:config',
  },
  {
    name: 'Customer Segments',
    component: GenericResource,
    resource: 'customerSegments',
    href: '/customer-segments',
    group: 'Customers & Orders',
    requiresPermission: 'write:config',
  },
  {
    name: 'Decoration Types',
    component: GenericResource,
    href: '/decoration-types',
    resource: 'decorationTypes',
    group: 'Production & Decoration',
    requiresPermission: 'write:config',
  },
  {
    name: 'Stock Colors',
    component: GenericResource,
    href: '/stock-colors',
    resource: 'stockColors',
    group: 'Production & Decoration',
    requiresPermission: 'write:config',
  },
  {
    name: 'Groups',
    component: GenericResource,
    href: '/groups',
    resource: 'groups',
    group: 'Users',
    requiresPermission: 'write:config',
  },
  {
    name: 'Search Purchased Items',
    component: GenericResource,
    href: '/po-items',
    resource: 'purchaseOrderItems',
    group: 'Sales',
    requiresPermission: 'read:purchase_orders',
    getHref: (i: PurchaseOrderItem) => `/purchase-orders/${i.purchase_order_id}?tab=items`,
  },
  {
    name: 'Shipping Methods',
    component: GenericResource,
    href: '/shipping-methods',
    resource: 'shippingMethods',
    group: 'Shipping',
    requiresPermission: 'write:config',
  },
  {
    name: 'Production Event Types',
    component: GenericResource,
    href: '/production-event-types',
    resource: 'productionEventTypes',
    group: 'Production & Decoration',
    requiresPermission: 'write:config',
  },
  {
    name: 'Production Machines',
    component: GenericResource,
    href: '/production-machines',
    resource: 'productionMachines',
    group: 'Production & Decoration',
    requiresPermission: 'write:config',
  },
  {
    name: 'Brands',
    component: GenericResource,
    href: '/brands',
    resource: 'brands',
    group: 'Products & Inventory',
    requiresPermission: 'read:products',
  },
  {
    name: 'Vendors',
    component: GenericResource,
    href: '/vendors',
    resource: 'vendors',
    group: 'Products & Inventory',
    requiresPermission: 'read:vendors',
  },
  {
    name: 'Inventory Location Groups',
    component: GenericResource,
    href: '/inventory-location-groups',
    resource: 'inventoryLocationGroups',
    group: 'Products & Inventory',
    requiresPermission: 'read:inventory_locations',
  },
  {
    name: 'Art Cost Items',
    component: GenericResource,
    href: '/art-cost-items',
    resource: 'artCostItems',
    group: 'Production & Decoration',
    requiresPermission: 'write:config',
  },
  {
    name: 'Special Design Types',
    component: GenericResource,
    href: '/special-design-types',
    resource: 'specialDesignTypes',
    group: 'Production & Decoration',
    requiresPermission: 'write:config',
  },
  {
    name: 'Bank Transaction Rules',
    component: GenericResource,
    href: '/banking-rules',
    group: 'Accounting',
    resource: 'bankingRules',
    requiresPermission: 'write:transactions',
  },
  {
    name: 'Payout History',
    component: GenericResource,
    resource: 'businessPayouts',
    href: '/business-payouts',
    group: 'Accounting',
    requiresPermission: 'read:businesses',
  },
  {
    name: 'Business Credits',
    component: GenericResource,
    resource: 'businessCredits',
    href: '/business-credits',
    group: 'Accounting',
    requiresPermission: 'write:order_payouts',
  },
  {
    name: 'Reconciliation',
    component: Reconciliation,
    href: '/reconciliation',
    group: 'Accounting',
    requiresPermission: 'write:transactions',
  },
  {
    name: 'Commission Models',
    component: GenericResource,
    href: '/commission-models',
    group: 'Accounting',
    resource: 'commissionModels',
    requiresPermission: 'write:order_commissions',
  },
  {
    name: 'Department Sets',
    component: GenericResource,
    href: '/department-sets',
    group: 'Customers & Orders',
    resource: 'departmentSets',
    requiresPermission: 'write:config',
  },
  {
    name: 'Box Types',
    component: GenericResource,
    href: '/box-types',
    group: 'Shipping',
    resource: 'boxTypes',
    requiresPermission: 'write:config',
  },
];

const pages: NavItem[] = [
  {
    name: 'Dashboard',
    component: Home,
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'Sales',
    icon: CartIcon,
    children: [
      {
        name: 'Orders',
        component: GenericResource,
        href: '/orders',
        resource: 'orders',
        requiresPermission: 'read:orders',
      },
      {
        name: 'Quotes',
        component: GenericResource,
        href: '/quotes',
        resource: 'quotes',
        requiresPermission: 'read:quotes',
      },
      {
        name: 'Projects',
        component: GenericResource,
        href: '/projects',
        resource: 'projects',
        requiresPermission: 'write:customers',
      },
      {
        name: 'Customers',
        component: GenericResource,
        href: '/customers',
        resource: 'customers',
        requiresPermission: 'read:customers',
      },
      {
        name: 'Receivables',
        component: Receivables,
        href: '/receivables',
        requiresPermission: 'orders:receivables',
      },
      {
        name: 'At-Risk',
        component: GenericResource,
        href: '/at-risk',
        resource: 'ordersAtRisk',
        requiresPermission: 'read:orders',
        getHref: (o: Order) => `/orders/${o.id}`,
      },
      {
        name: 'Pricing Engine',
        component: PricingEnginePage,
        href: '/pricing',
        requiresPermission: 'write:orders',
      },
    ],
  },
  {
    name: 'Art',
    icon: ArtTrackIcon,
    children: [
      {
        name: 'Art Requests',
        component: GenericResource,
        href: '/art-requests',
        resource: 'artRequests',
        requiresPermission: 'read:art_requests',
      },
      {
        name: 'Art Activities',
        component: GenericResource,
        href: '/art-request-activities',
        resource: 'artRequestActivities',
        requiresPermission: 'read:art_request_activities',
      },
      {
        name: 'Designs',
        component: GenericResource,
        href: '/designs',
        resource: 'designs',
        requiresPermission: 'read:designs',
      },
      {
        name: 'Design Layouts',
        component: GenericResource,
        href: '/design-layouts',
        resource: 'designLayouts',
        requiresPermission: 'read:designs',
        getHref: (d: DesignLayout) => `/orders/${d.order_id}?tab=art&layout_id=${d.id}`,
      },
      {
        name: 'Design Library',
        component: GenericResource,
        href: '/design-library',
        resource: 'designLibrary',
        requiresPermission: 'read:design_library',
      },
    ],
  },
  {
    name: 'Purchasing',
    icon: ReceiptIcon,
    children: [
      {
        name: 'Orders to Purchase',
        component: GenericResource,
        href: '/purchasing',
        resource: 'ordersToPurchase',
        requiresPermission: 'write:purchase_orders',
      },
      {
        name: 'Purchase Orders',
        component: GenericResource,
        href: '/purchase-orders',
        resource: 'purchaseOrders',
        requiresPermission: 'read:purchase_orders',
      },
      {
        name: 'Vendors',
        component: GenericResource,
        href: '/vendors',
        resource: 'vendors',
        requiresPermission: 'read:vendors',
      },
      {
        name: 'Inbound Shipments',
        component: GenericResource,
        href: '/inbound-shipments',
        resource: 'inboundShipments',
        requiresPermission: 'read:inbound_shipments',
      },
    ],
  },
  {
    name: 'Receiving',
    icon: InboxIcon,
    children: [
      {
        name: 'By Purchase Order',
        title: 'Receiving by Purchase Order',
        component: GenericResource,
        href: '/receiving-po',
        resource: 'purchaseOrdersToReceive',
        requiresPermission: 'write:receivings',
      },
      {
        name: 'By Order',
        title: 'Receiving by Order',
        component: GenericResource,
        href: '/receiving-order',
        resource: 'ordersToReceive',
        requiresPermission: 'write:receivings',
      },
      {
        name: 'Issues',
        title: 'Receiving Issues',
        component: GenericResource,
        href: '/receiving-issues',
        resource: 'receivingIssues',
        requiresPermission: 'read:receivings',
      },
    ],
  },
  {
    name: 'Production',
    icon: BuildIcon,
    children: [
      {
        name: 'Orders to Produce',
        component: GenericResource,
        href: '/production',
        resource: 'ordersToProduce',
        requiresPermission: 'write:production_events',
      },
      {
        name: 'All Events',
        component: GenericResource,
        href: '/production-events',
        resource: 'productionEvents',
        requiresPermission: 'read:production_events',
        getHref: (e: ProductionEvent) => `/production/${e.order_id}?event_id=${e.id}`,
      },
      {
        name: 'Schedule',
        component: ProductionSchedule,
        href: '/production-schedule',
        requiresPermission: 'read:production_events',
      },
      {
        name: 'Forecast',
        component: ProductionForecast,
        href: '/production-forecast',
        requiresPermission: 'production_events:admin',
      },
    ],
  },
  {
    name: 'Shipping',
    icon: LocalShippingIcon,
    children: [
      {
        name: 'Orders to Ship',
        component: GenericResource,
        href: '/shipping',
        resource: 'ordersToShip',
        requiresPermission: 'write:shipments',
      },
      {
        name: 'Shipments',
        component: GenericResource,
        href: '/shipments',
        resource: 'shipments',
        requiresPermission: 'read:shipments',
        getHref: (s: Shipment) =>
          `/${s.shippable_type === 'fulfillment_order' ? 'fulfillment-orders' : 'orders'}/${
            s.shippable?.id
          }?tab=shipping`,
      },
    ],
  },
  {
    name: 'Fulfillment',
    icon: Inventory,
    children: [
      {
        name: 'Orders',
        component: GenericResource,
        href: '/fulfillment-orders',
        resource: 'fulfillmentOrders',
        requiresPermission: 'read:fulfillment_orders',
        countKey: 'backorderCount',
      },
      {
        name: 'Picks',
        component: GenericResource,
        href: '/inventory-picks',
        resource: 'inventoryPicks',
        requiresPermission: 'read:inventory_picks',
      },
      {
        name: 'Returns',
        component: GenericResource,
        href: '/inventory-returns',
        resource: 'inventoryReturns',
        requiresPermission: 'read:inventory_picks',
      },
      {
        name: 'Adjustments',
        component: GenericResource,
        href: '/inventory-adjustments',
        resource: 'inventoryAdjustments',
        requiresPermission: 'write:inventory_adjustments',
        isGlobal: true,
      },
      {
        name: 'SKUs',
        component: GenericResource,
        href: '/inventory-variants',
        resource: 'inventoryVariants',
        requiresPermission: 'read:products',
      },
      {
        name: 'Locations',
        component: GenericResource,
        href: '/inventory-locations',
        resource: 'inventoryLocationsForPage',
        group: 'Products',
        requiresPermission: 'read:inventory_locations',
      },
      {
        name: 'Packouts',
        component: GenericResource,
        href: '/packouts',
        resource: 'orderPackouts',
        requiresPermission: 'read:packouts',
      },
    ],
  },
  {
    name: 'Products',
    icon: TagIcon,
    children: [
      {
        name: 'Find Products',
        component: ProductSearch,
        href: '/product-search',
        requiresPermission: 'read:products',
      },
      {
        name: 'All Products',
        component: GenericResource,
        href: '/products',
        resource: 'products',
        requiresPermission: 'write:products',
      },
      {
        name: 'Inventory by SKU',
        component: GenericResource,
        href: '/inventory-variants',
        resource: 'inventoryVariants',
        requiresPermission: 'read:products',
      },
    ],
  },
  {
    name: 'Accounting',
    isGlobal: true,
    icon: MoneyIcon,
    children: [
      {
        name: 'Banking',
        component: Banking,
        href: '/banking',
        requiresPermission: 'read:accounts',
      },
      {
        name: 'Transactions',
        component: GenericResource,
        href: '/transactions',
        resource: 'transactions',
        requiresPermission: 'read:transactions',
      },
      {
        name: 'Customer Payments',
        component: GenericResource,
        resource: 'payments',
        href: '/payments',
        requiresPermission: 'read:payments',
      },
      {
        name: 'Deposits',
        component: GenericResource,
        href: '/deposits',
        resource: 'deposits',
        requiresPermission: 'read:deposits',
      },
      {
        name: 'Journal Entries',
        component: GenericResource,
        href: '/journal-entries',
        resource: 'journalEntries',
        requiresPermission: 'read:journal_entries',
      },
      {
        name: 'General Ledger',
        component: GenericResource,
        getHref: (r: LedgerEntry) => getAssociatedLinkForEntry(r),
        resource: 'ledgerEntries',
        href: '/gl',
        requiresPermission: 'read:ledger_entries',
      },
      {
        name: 'Financial Statements',
        component: AccountingReports,
        href: '/accounting',
        requiresPermission: 'read:accounting_reports',
      },
      {
        name: 'Chart of Accounts',
        component: GenericResource,
        href: '/accounts',
        resource: 'accounts',
        requiresPermission: 'read:accounts',
      },
    ],
  },
  {
    name: 'Reports',
    href: '/reports',
    icon: BarChartIcon,
    component: Reports,
  },
  {
    name: 'Tools',
    icon: BuildIcon,
    children: [
      {
        name: 'Collect a Payment',
        component: CollectPayment,
        href: '/collect-payment',
        requiresPermission: 'payments:pos',
      },
      {
        name: 'Print Checks',
        component: PrintChecks,
        href: '/print-checks',
        requiresPermission: 'transactions:print_checks',
      },
      {
        name: 'Import Transactions',
        component: TransactionImporter,
        href: '/import-transactions',
        requiresPermission: 'write:transactions',
      },
      {
        name: 'Rebates',
        component: GenericResource,
        href: '/rebates',
        resource: 'rebates',
        requiresPermission: 'write:payments',
      },
      {
        name: 'Perform Payouts',
        component: Payouts,
        href: '/perform-payouts',
        requiresPermission: 'write:businesses',
      },
      {
        name: 'Bulk Customer Update',
        component: CustomerUpdate,
        href: '/customer-update',
        requiresPermission: 'write:customers',
      },
      {
        name: 'Search PO Items',
        component: GenericResource,
        href: '/po-items',
        resource: 'purchaseOrderItems',
        requiresPermission: 'read:purchase_orders',
        getHref: (i: PurchaseOrderItem) => `/purchase-orders/${i.purchase_order_id}?tab=items`,
      },
    ],
  },
  {
    name: 'Settings',
    icon: SettingsIcon,
    href: '/settings',
    component: Settings,
    isGlobal: true,
  },
  {
    name: 'My Business',
    icon: DashboardTwoTone,
    href: '/business',
    component: MyBusiness,
    requiresPermission: 'write:order_commissions',
  },
];

export default pages;
