import { Print } from '@mui/icons-material';
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import { useNavigate } from 'react-router-dom';
import { ButtonAction } from '@/classes';
import AddressBlock from '@/components/Addresses/AddressBlock';
import FulfillmentOrderCard from '@/components/FulfillmentOrders/FulfillmentOrderCard';
import EventableCard from '@/components/Notes/EventableCard';
import StatusChip from '@/components/Shared/StatusChip';
import SubMenu from '@/components/Shared/SubMenu';
import WithLabel from '@/components/Shared/WithLabel';
import ShipActions from '@/components/Shipping/ShipActions';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { PARTY_COLORS, PICK_STATUS_COLORS } from '@/constants';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import useScanDetection from '@/hooks/useScanDetection';
import { FulfillmentOrder, InventoryPick, InventoryPickItem, SkuResource } from '@/types';
import { formatTimestamp } from '@/utils/dates';
import { useOnReloadRecord, useRecord, useUpdateRecord } from '@/utils/genericResource';
import getApiUrl from '@/utils/getApiUrl';
import OrderSummaryCard from '../Orders/OrderSummaryCard';
import InventoryPickItems, { GIFT_NOTE_SKU } from './InventoryPickItems';

export type PickAction =
  | 'start'
  | 'complete'
  | 'ship'
  | 'hold'
  | 'unhold'
  | 'request_cancellation'
  | 'reset'
  | 'delete'
  | 'reship'
  | 'printed';

const SHIPMENT_STATUSES: InventoryPick['status'][] = [
  'complete',
  'pending_shipment',
  'cancellation_requested',
];

function getGiftNotePickItem(order: FulfillmentOrder): InventoryPickItem {
  return {
    id: -1,
    inventory_pick_id: -1,
    variant_id: -1,
    sku: GIFT_NOTE_SKU,
    description: 'Print a gift note',
    qty: 1,
    weight: 0,
    image: '',
    location: null,
    picked_at: order.gift_note_printed_at,
    variant: { id: -1, sku: GIFT_NOTE_SKU } as unknown as SkuResource,
    upc: null,
    location_id: null,
    pickable_qty: 0,
    inventory_qty: 0,
  };
}

export default function InventoryPickPage() {
  const pick = useRecord('inventoryPicks');
  const onReload = useOnReloadRecord('inventoryPicks');
  const updateRecord = useUpdateRecord('inventoryPicks');
  const { items, address } = pick;
  const inventoryPickPrintableId = useConfig().printables.find(
    (p) => p.template === 'InventoryPickTemplate',
  )?.id;
  const giftNotePrintableId = useConfig().printables.find(
    (p) => p.template === 'GiftNoteTemplate',
  )?.id;
  const giftNotePrintUrl = getApiUrl(`/api/printables/${giftNotePrintableId}/render/${pick.id}`);
  const navigate = useNavigate();
  const { alert, confirm } = useDialogs();
  const isMobile = useMediaQuery('(max-width: 1000px)');
  const hasPermission = useHasPermission();
  const showLoading = useShowLoading();
  const giftNoteIsPrinted = Boolean(
    'gift_note_printed_at' in pick.pickable && pick.pickable.gift_note_printed_at,
  );

  useScanDetection({
    onComplete: (data) => {
      const isValid = data === `gift_note_${pick.pickable.id}`;
      if (!isValid) {
        alert({
          title: 'Barcode mismatch',
          description: `Pick does not match barcode scanned: "${data}"`,
        });
      } else {
        performMethod('printed');
      }
    },
  });

  const actions: Record<PickAction, [title: string, desc?: string, color?: AlertColor]> = {
    start: [
      'Start Picking',
      'This will specify bins to pull from and move the status to "Picking". Are you sure you want to proceed?',
    ],
    complete: [
      'Complete Picking',
      pick.party === 'avail'
        ? 'The items in this order are part of an Avail sales order. Shipping is not required. Rather, items should be placed on the production shelf. This action will move the pick to the "Completed" status. Are you sure you want to proceed?'
        : 'The items in this pick need to be shipped. This action will move the status to "Pending Shipment". Are you sure you want to proceed?',
    ],
    hold: ['Put on Hold'],
    unhold: ['Remove Hold'],
    request_cancellation: [
      'Request Cancellation',
      'This will move the status to "Cancellation Requested" and give the picker the opportunity to put items back in the bins before deleting the pick. Are you sure you want to proceed?',
      'warning',
    ],
    reset: [
      'Reset',
      'This will revert any inventory entries and move the pick back to the "Issued" status. Are you sure you want to proceed?',
      'warning',
    ],
    delete: [
      'Delete',
      'This will permanently delete the pick. Are you sure you want to proceed?',
      'error',
    ],
    reship: [
      'Reship',
      'This will set the status back to "Pending Shipment" so you can ship it out again',
      'warning',
    ],
    ship: ['Ship'],
    printed: giftNoteIsPrinted
      ? ['Unprint Gift Note', 'This will mark the gift note as not printed.']
      : ['Confirm Gift Note Printed', 'This will mark the gift note as printed.'],
  };

  const transitions: Record<InventoryPick['status'], (keyof typeof actions)[]> = {
    issued: ['start', 'hold', 'delete'],
    picking: ['complete', 'reset', 'request_cancellation'],
    on_hold: ['unhold'],
    complete: ['request_cancellation', 'reset', 'reship'],
    pending_shipment: ['ship', 'request_cancellation', 'reset'],
    cancellation_requested: ['delete'],
  };

  const performMethod = (method: PickAction) => {
    showLoading(
      axios.post(`/api/inventory-picks/${pick.id}/${method}`).then(({ data }) => {
        if (method === 'delete') {
          navigate('/inventory-picks');
        } else {
          updateRecord(data);
        }
      }),
    );
  };

  const onMethod = (method: PickAction) => () => {
    confirm({
      title: actions[method][0],
      description: actions[method][1] || 'Are you sure you want to proceed?',
      color: actions[method][2],
    }).then(() => {
      performMethod(method);
    });
  };

  const printButton = inventoryPickPrintableId && pick.status !== 'issued' && (
    <IconButton
      component="a"
      target="_blank"
      href={getApiUrl(`/api/printables/${inventoryPickPrintableId}/render/${pick.id}`)}
    >
      <Print />
    </IconButton>
  );

  const getButtonForPrimaryAction = (action: PickAction) => {
    if (action === 'ship') {
      if (pick.party === 'avail') {
        return (
          <Button variant="contained" onClick={onMethod('complete')}>
            Complete
          </Button>
        );
      }
      if (address) {
        return (
          <ShipActions
            payload={{
              shippable_type: pick.pickable_type,
              shippable_id: pick.pickable_id,
              address_id: address.id,
              inventory_pick_id: pick.id,
            }}
            customerId={pick.pickable.customer?.id}
            title={`Shipping ${pick.number}`}
            qty={sumBy(items, 'qty')}
            onSuccess={onReload}
            address={address}
          />
        );
      }
    }
    return (
      <Button variant="contained" onClick={onMethod(action)}>
        {actions[action][0]}
      </Button>
    );
  };

  const [primary, ...other] = transitions[pick.status];

  const action = (
    <Stack direction="row" spacing={1} alignItems="center">
      {getButtonForPrimaryAction(primary)}
      {printButton}
      {other.length > 0 && (
        <SubMenu items={other.map((m) => new ButtonAction(actions[m][0], onMethod(m)))} />
      )}
    </Stack>
  );

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 9 }}>
        <Stack spacing={2}>
          <Card>
            <CardHeader
              title={pick.number}
              subheader={`Location Group: ${pick.location_group?.name}`}
              action={!isMobile && hasPermission('write:inventory_picks') && action}
            />
            {isMobile && <Box px={2}>{action}</Box>}
            <CardContent>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Pick Status">
                    <StatusChip status={pick.status} colors={PICK_STATUS_COLORS} />
                  </WithLabel>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Destination">
                    <StatusChip status={pick.party} colors={PARTY_COLORS} />
                  </WithLabel>
                </Grid>

                <Grid size={{ xs: 12, md: 3 }}>
                  <WithLabel label="Order">
                    <Typography>
                      {pick.pickable_type === 'fulfillment_order'
                        ? pick.pickable.number
                        : pick.pickable.increment_id}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Placed: {formatTimestamp(pick.ordered_at)}
                    </Typography>
                  </WithLabel>
                </Grid>

                {address && (
                  <Grid size={{ xs: 12, md: 3 }}>
                    <WithLabel label="Address">
                      <AddressBlock address={address} />
                    </WithLabel>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card>
            <InventoryPickItems
              items={
                'gift_note' in pick.pickable && pick.pickable.gift_note
                  ? [...items, getGiftNotePickItem(pick.pickable)]
                  : items
              }
              status={pick.status}
              onTogglePrinted={onMethod('printed')}
              giftNotePrintUrl={giftNotePrintUrl}
            />
          </Card>

          {pick.party === 'customer' && SHIPMENT_STATUSES.includes(pick.status) && (
            <ShipmentsCard
              shippableType={pick.pickable_type}
              shippableId={pick.pickable.id}
              showActions
            />
          )}
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        {pick.pickable_type === 'fulfillment_order' ? (
          <FulfillmentOrderCard order={pick.pickable} />
        ) : (
          <OrderSummaryCard order={pick.pickable} />
        )}

        <EventableCard resourceId={pick.id} resource="picks" size="small" />
      </Grid>
    </Grid>
  );
}
