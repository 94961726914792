import { Grid2 as Grid, Stack } from '@mui/material';
import uniq from 'lodash/uniq';
import IssuesCard from '@/components/Issues/IssuesCard';
import OrderSummaryCard from '@/components/Orders/OrderSummaryCard';
import ItemsToReceive from '@/components/Receiving/ItemsToReceive';
import ReceivingsList from '@/components/Receiving/ReceivingsList';
import { useRecord } from '@/utils/genericResource';

export default function ReceivingByOrder() {
  const record = useRecord('ordersToReceive');

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, lg: 9 }}>
        <Stack spacing={2}>
          <ItemsToReceive
            receivableId={record.id}
            receivableType="order"
            purchaseOrderIds={uniq(record.purchase_order_items.map((i) => i.purchase_order_id))}
            orderIds={[record.id]}
            items={record.purchase_order_items}
            shippingMode={record.shipping_mode}
          />
          <ReceivingsList receivableId={record.id} receivableType="order" />
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, lg: 3 }}>
        <Stack spacing={2}>
          <OrderSummaryCard order={record} />
          <IssuesCard orderId={record.id} canCreate type="receiving" />
        </Stack>
      </Grid>
    </Grid>
  );
}
