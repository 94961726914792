import { Autocomplete, Box, styled, TextField, TextFieldProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useGetCustomerBrand } from '@/api/customers';
import ColorBadge, { MetallicOverlay } from '@/components/Designs/ColorBadge';
import { ComplexityUnit, Customer, StockColor } from '@/types';
import { isScreenPrint } from '@/utils/helpers';

function getGroupLabel(c: { priority: number | null }) {
  if (c.priority === 1) {
    return 'Priority';
  }
  if (c.priority === 2) {
    return 'Secondary';
  }
  if (c.priority === 3) {
    return 'Customer Brand';
  }
  return 'Other';
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  backgroundColor: theme.palette.background.default,
}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default function ColorChooser({
  decorationUnit,
  disabled = false,
  value,
  onChange,
  label = 'Color',
  freeSolo = false,
  size,
  customer,
  textFieldProps = {},
}: {
  decorationUnit: ComplexityUnit | null;
  disabled?: boolean;
  value: string | null;
  onChange: (value: string | null) => void;
  freeSolo?: boolean;
  label?: string;
  size?: 'small' | 'medium';
  customer?: Customer;
  textFieldProps?: Partial<TextFieldProps>;
}) {
  const { data: brandData } = useGetCustomerBrand(customer?.id);
  const { data: stockColors = [] } = useQuery(
    ['stockColors', decorationUnit],
    () =>
      axios
        .get<{ data: StockColor[] }>(`/api/stock-colors?filter[unit]=${decorationUnit}&count=2000`)
        .then(({ data }) => data.data),
    {
      enabled: decorationUnit != null,
      staleTime: Infinity,
    },
  );

  const brandColors = (brandData?.brand_colors || [])
    .map((bc) => ({
      name: isScreenPrint(decorationUnit) ? bc.ink_color : bc.thread_color,
      hex: bc.hex,
      priority: 3,
      is_metallic: false,
    }))
    .filter((bc) => bc.name != null);

  const options = [...brandColors, ...stockColors].map((c) => ({
    ...c,
    value: c.name,
    label: c.name,
    group: getGroupLabel(c),
  }));

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      freeSolo={freeSolo}
      options={options}
      sx={{ minWidth: 200 }}
      groupBy={(option) => (typeof option === 'string' ? '' : option.group)}
      value={freeSolo ? value : options.find((o) => o.value === value)}
      onInputChange={(e, value) => {
        if (freeSolo) {
          onChange(value);
        }
      }}
      onChange={(e, value) => {
        if (value == null) {
          onChange(null);
        } else if (typeof value === 'object') {
          onChange(value.value);
        }
      }}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          {...textFieldProps}
          disabled={disabled}
          size={size}
          label={label}
          margin="dense"
          inputProps={{
            ...inputProps,
            value,
          }}
        />
      )}
      renderOption={(props, option) => {
        if (typeof option === 'string') {
          return option;
        }
        return (
          <Box display="flex" alignItems="center" component="li" {...props}>
            {option.hex && (
              <ColorBadge color={option.hex} size={20} sx={{ mr: 1 }}>
                {option.is_metallic && <MetallicOverlay />}
              </ColorBadge>
            )}
            <div>{option.name}</div>
          </Box>
        );
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
}
