import { useCallback, useEffect, useMemo, useState } from 'react';
import { Inventory, Undo } from '@mui/icons-material';
import { Box, Button, CardHeader, Checkbox, IconButton, TextField, Tooltip } from '@mui/material';
import { CellContext, createColumnHelper, Row } from '@tanstack/react-table';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';
import { z } from 'zod';
import { fetchAddresses } from '@/api/addresses';
import { useCreateReceiving, useUnreceivePoItem } from '@/api/receiving';
import { FieldFactory } from '@/classes';
import PaginatedTable from '@/components/DataTable/PaginatedTable';
import OrderLabel from '@/components/Orders/OrderLabel';
import SkuLabel from '@/components/Products/SkuLabel';
import PurchaseOrderItemQtyReceived from '@/components/Receiving/PurchaseOrderItemQtyReceived';
import TextLink from '@/components/Text/TextLink';
import { useDialogs } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import useMarkAsShipped from '@/hooks/useMarkAsShipped';
import {
  Address,
  PurchaseOrderItem,
  Receiving,
  ReceivingItem,
  ReceivingPayload,
  ShipmentBasePayload,
  ShippingMode,
} from '@/types';
import { buildIncrementId } from '@/utils/notes';
import numString from '@/utils/numString';
import { getQtyInputProps } from '@/utils/shipping';

export function getReceivingFields() {
  return [
    FieldFactory.images('images'),
    FieldFactory.list('barcodes', [FieldFactory.text('barcode').withColumnSpan(10)]).with({
      addNewLabel: 'Add Barcode +',
      addNewValue: {
        barcode: '',
      },
    }),
  ];
}

export default function ItemsToReceiveTable({
  title,
  items,
  receivableId,
  receivableType,
  canReceive: canReceiveProp = false,
  shippingMode,
}: {
  title: string;
  items: PurchaseOrderItem[];
  receivableId: number;
  receivableType: 'order' | 'purchase_order';
  canReceive?: boolean;
  shippingMode?: ShippingMode;
}) {
  const [selected, setSelected] = useState<Record<number, number>>({});
  const { prompt, confirm } = useDialogs();
  const hasPermission = useHasPermission();
  const createRequest = useCreateReceiving(receivableType, receivableId);
  const unreceiveRequest = useUnreceivePoItem(receivableType, receivableId);

  const numSelected = Object.keys(selected).length;
  const qtySelected = sumBy(Object.values(selected));
  const canReceive = canReceiveProp && hasPermission('write:receivings');
  const { markAsShipped } = useMarkAsShipped({
    title,
    onSuccess: () => setSelected([]),
    shippingMode,
  });

  useEffect(() => {
    setSelected({});
  }, [receivableId, receivableType, items]);

  const areAddressesSame = (addressA: Address, addressB: Address): boolean => {
    return (
      addressA.address_1 === addressB.address_1 &&
      addressA.zip === addressB.zip &&
      addressA.state === addressB.state
    );
  };

  const shouldMarkAsShipped = async (receiving: Receiving) => {
    if (!shippingMode) {
      // Only mark as shipped if we know the shipping mode
      return false;
    }
    const addressCache = new Map<number, Address[]>();
    let orderAddressId = null;
    const allItemsValid = await Promise.all(
      receiving.items!.map(async (item: ReceivingItem) => {
        const orderId = item.purchase_order_item.order_id!;
        let addresses = addressCache.get(orderId);

        if (orderId !== receiving.items![0].purchase_order_item.order_id) {
          return false;
        }

        if (!addresses) {
          addresses = await fetchAddresses('orders', orderId);
          addressCache.set(orderId, addresses);
        }

        const areAddressesTheSame = areAddressesSame(
          addresses[0],
          item.purchase_order_item.purchase_order!.address,
        );
        orderAddressId = addresses[0].id;
        const isCustomer = item.purchase_order_item.purchase_order!.party === 'customer';

        return isCustomer && areAddressesTheSame;
      }) ?? [],
    );

    if (!allItemsValid.every((isValid) => isValid)) {
      return false;
    }

    let qty = 0;
    const payload = {
      shippable_type: 'order',
      shippable_id: receiving.items![0].purchase_order_item.order_id,
      address_id: orderAddressId,
      items: receiving
        .items!.filter((item: ReceivingItem) => item.purchase_order_item.order_item_id)
        .map((item: ReceivingItem) => {
          qty += item.qty_received;
          return {
            order_item_id: item.purchase_order_item.order_item_id,
            qty_shipped: item.qty_received,
          };
        }),
    } as ShipmentBasePayload;

    return { payload, qty };
  };

  const onReceive = () => {
    if (!canReceive) {
      return;
    }

    const payload: ReceivingPayload = {
      [`${receivableType}_id`]: receivableId,
      items: Object.entries(selected).map(([k, v]) => ({
        purchase_order_item_id: Number(k),
        qty_received: v,
      })),
    };
    prompt({
      title: `Receive ${numString(qtySelected, 'Items')}`,
      description:
        'Optionally upload image(s) of the packing list and/or scan the carrier barcode(s)',
      submitText: 'Receive',
      initialValues: {
        images: [],
        barcodes: [],
      },
      schema: z.object({
        images: z.array(
          z.object({
            url: z.string(),
          }),
        ),
        barcodes: z.array(
          z.object({
            barcode: z.string(),
          }),
        ),
      }),
      fields: getReceivingFields(),
      onSubmit: (v) =>
        createRequest.mutateAsync({
          ...payload,
          barcodes: v.barcodes.map((b) => b.barcode),
          images: v.images.map((i) => i.url),
        }),
    })
      .then((receiving: Receiving) => {
        setSelected([]);
        return receiving;
      })
      .then(async (receiving: Receiving) => {
        const canMarkAsShipped = await shouldMarkAsShipped(receiving);
        if (!canMarkAsShipped) {
          return;
        }

        return markAsShipped(canMarkAsShipped.payload, canMarkAsShipped.qty);
      });
  };

  const onUnreceive = useCallback(
    (item: PurchaseOrderItem) => {
      confirm({
        title: `Unreceive ${numString(item.qty_received, 'Items')}`,
        description: 'Are you sure you want to unreceive these items?',
      }).then(() => {
        unreceiveRequest.mutate(item.id);
      });
    },
    [unreceiveRequest.mutate],
  );

  const rows = useMemo(() => {
    return items.map((item) => ({
      ...item,
      detail: `${item.description}: ${item.number} - ${item.color} - ${item.size}`,
    }));
  }, [items]);

  const initialIsGrouped = useMemo(
    () => Object.values(groupBy(rows, 'detail')).some((r) => r.length > 1),
    [rows],
  );

  const columns = useMemo(() => {
    const getCheckboxForRows = (items: PurchaseOrderItem[], rowsToExpand?: Row<any>[]) => {
      if (!canReceive) {
        return null;
      }

      return (
        <Checkbox
          checked={items.length > 0 && items.every((i) => i.id in selected)}
          indeterminate={items.some((i) => selected[i.id]) && !items.every((i) => i.id in selected)}
          onChange={(e) => {
            setSelected((prev) => {
              const others = omit(
                prev,
                items.map((i) => i.id),
              );
              if (!e.currentTarget.checked) {
                return others;
              }
              return {
                ...others,
                ...items.reduce(
                  (agg, i) => {
                    agg[i.id] = i.qty - i.qty_received;
                    return agg;
                  },
                  {} as Record<number, number>,
                ),
              };
            });
            rowsToExpand?.forEach((row) => {
              if (row.getCanExpand()) {
                row.toggleExpanded(true);
              }
            });
          }}
        />
      );
    };

    const getTextInputForRows = (items: PurchaseOrderItem[]) => {
      if (items.length > 1 || items.length === 0) {
        return null;
      }
      const item = items[0]!;
      if (item.id in selected) {
        return (
          <TextField
            style={{ minWidth: 70 }}
            size="small"
            type="number"
            slotProps={{
              htmlInput: getQtyInputProps(item.qty - item.qty_received, item.qty),
            }}
            defaultValue={selected[item.id]}
            onChange={(e) => {
              const value = Number(e.target.value);
              setSelected((prev) => ({
                ...prev,
                [item.id]: value,
              }));
            }}
          />
        );
      }
      return null;
    };

    const columnHelper = createColumnHelper<PurchaseOrderItem & { detail: string }>();

    return [
      columnHelper.display({
        id: 'checkbox',
        enableHiding: false,
        header: ({ table }) => getCheckboxForRows(items, table.getRowModel().rows),
        aggregatedCell: ({ row }) =>
          getCheckboxForRows(
            row.subRows.map((r) => r.original),
            [row],
          ),
        cell: ({ row }) => getCheckboxForRows([row.original]),
      }),
      columnHelper.accessor('order_id', {
        header: 'Order',
        aggregatedCell: ({ getValue }) => buildIncrementId('SO', getValue()),
        cell: ({ getValue }) => <OrderLabel orderId={getValue()} />,
      }),
      columnHelper.accessor('purchase_order_id', {
        header: 'PO',
        aggregatedCell: ({ getValue }) => buildIncrementId('PO', getValue()),
        cell: ({ row, getValue }) => (
          <Box display="flex" alignItems="center" gap={1}>
            <TextLink to={`/purchase-orders/${getValue()}`}>
              {buildIncrementId('PO', getValue())}
            </TextLink>
            {row.original.is_inventory && (
              <Tooltip title="This item is going into inventory">
                <Inventory
                  color="primary"
                  fontSize="small"
                  sx={{ verticalAlign: 'bottom', ml: 1 }}
                />
              </Tooltip>
            )}
          </Box>
        ),
      }),
      columnHelper.accessor('detail', {
        header: 'Detail',
        enableHiding: false,
      }),
      columnHelper.accessor('description', {
        header: 'Description',
      }),
      columnHelper.accessor('number', {
        header: 'Number',
      }),
      columnHelper.accessor('color', {
        header: 'Color',
      }),
      columnHelper.accessor('size', {
        header: 'Size',
      }),
      columnHelper.accessor('variant.sku', {
        header: 'SKU',
        cell: ({ row }) =>
          row.original.variant ? <SkuLabel variant={row.original.variant} /> : null,
      }),
      columnHelper.accessor('variant.upc', {
        header: 'UPC',
      }),
      columnHelper.accessor('variant.gtin', {
        header: 'GTIN',
      }),
      columnHelper.accessor('qty', {
        header: 'Qty Pur',
        aggregationFn: 'sum',
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.accessor('qty_received', {
        header: 'Qty Rec',
        aggregationFn: 'sum',
        cell: ({ row }) => <PurchaseOrderItemQtyReceived item={row.original} />,
        meta: {
          aggregatable: true,
        },
      }),
      columnHelper.display({
        id: 'actions',
        enableHiding: false,
        aggregatedCell: ({ row }: CellContext<PurchaseOrderItem & { detail: string }, any>) =>
          getTextInputForRows(row.subRows.map((r) => r.original)),
        cell: ({ row }: CellContext<PurchaseOrderItem & { detail: string }, any>) => (
          <Box display="flex" alignItems="center" gap={1} my={-1}>
            {getTextInputForRows([row.original])}
            {row.original.qty_received !== 0 && (
              <Tooltip title="Unreceive Items">
                <IconButton size="small" onClick={() => onUnreceive(row.original)}>
                  <Undo fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        ),
      }),
    ];
  }, [numSelected, setSelected, onUnreceive]);

  return (
    <>
      <CardHeader
        title={title}
        action={
          numSelected > 0 && (
            <Button onClick={onReceive} variant="contained">
              Receive {numString(qtySelected, 'Items')}
            </Button>
          )
        }
      />

      <PaginatedTable
        storageKey={`itemsToReceive-${canReceive}-${initialIsGrouped}`}
        rows={rows}
        definedGrouping={['detail']}
        initialIsGrouped={initialIsGrouped}
        getRowCanExpand={(row) => row.subRows.length > 1}
        columns={columns}
        initialState={{
          sums: {
            qty: true,
            qty_received: true,
          },
          columnVisibility: {
            checkbox: canReceive,
            variant_sku: false,
            variant_upc: false,
            variant_gtin: false,
            detail: false,
          },
        }}
      />
    </>
  );
}
