import { ReactNode, useState } from 'react';
import { Button, Stack } from '@mui/material';
import { FieldFactory } from '@/classes';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import FormField from '@/components/Form/FormField';
import Can from '@/components/Permissions/Can';
import { ITEM_CLASSIFICATIONS } from '@/constants';

export default function BehaviorSection({ children }: { children?: ReactNode }) {
  const [showAdvanced, setShowAdvanced] = useState(false);

  return (
    <DrawerFormSection title="Behavior">
      <FormField field={FieldFactory.boolean('is_purchasable', 'Purchasable')} />
      <FormField field={FieldFactory.boolean('is_customer_supplied', 'Customer Supplied')} />
      <FormField field={FieldFactory.boolean('can_apply_designs', 'Can Apply Designs')} />
      <FormField field={FieldFactory.boolean('is_shippable', 'Shippable')} />
      <FormField field={FieldFactory.boolean('show_on_invoice', 'Show on Invoice')} />
      <Can permission="orders:tax_exempt">
        <FormField field={FieldFactory.boolean('is_tax_exempt', 'Tax Exempt')} />
      </Can>

      {showAdvanced && (
        <Stack spacing={3} sx={{ mt: 2 }}>
          <FormField field={FieldFactory.select('classification', ITEM_CLASSIFICATIONS)} />
          <FormField field={FieldFactory.textarea('note')} />
          <FormField field={FieldFactory.image('image')} />
          <FormField field={FieldFactory.number('inventory_unit_conversion')} />
          <FormField field={FieldFactory.text('upc').withLabel('UPC')} />
          <FormField field={FieldFactory.text('bin')} />
        </Stack>
      )}

      <Button onClick={() => setShowAdvanced((p) => !p)} sx={{ mt: 2 }} size="small">
        {showAdvanced ? 'Hide' : 'Show'} Advanced
      </Button>

      {children}
    </DrawerFormSection>
  );
}
