import AddBoxIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Card,
  CardHeader,
  IconButton,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { z } from 'zod';
import {
  useCreateAddressForOrder,
  useDeleteAddressForOrder,
  useGetAddressesForOrder,
  useUpdateAddressForOrder,
} from '@/api/addresses';
import { ButtonAction, FieldFactory } from '@/classes';
import Can from '@/components/Permissions/Can';
import SkuLabel from '@/components/Products/SkuLabel';
import SubMenu from '@/components/Shared/SubMenu';
import { useConfig } from '@/contexts/AppConfigContext';
import { useDialogs } from '@/contexts/DialogContext';
import { Address, genericModelReferenceSchema } from '@/types';
import { useRecord } from '@/utils/genericResource';

export default function OrderInventoryVariants({ onSelect }: { onSelect: (a: Address) => void }) {
  const {
    id: orderId,
    customer: { id: customerId },
  } = useRecord('orders');
  const { prompt, confirm } = useDialogs();
  const { availAddress } = useConfig();
  const { data: addresses = [], isFetching } = useGetAddressesForOrder('orders', orderId);
  const createRequest = useCreateAddressForOrder('orders', orderId);
  const updateRequest = useUpdateAddressForOrder('orders', orderId);
  const deleteRequest = useDeleteAddressForOrder('orders', orderId);

  const onAddVariant = () => {
    prompt({
      title: 'Add Kit SKU',
      description: 'Find the SKU of the kit you would like to add to.',
      fields: [
        FieldFactory.belongsTo('inventory_variant', 'inventoryVariants').withRequestParams({
          'filter[customer_id]': customerId,
        }),
      ],
      schema: z.object({
        inventory_variant: genericModelReferenceSchema,
      }),
      onSubmit: (v) =>
        createRequest.mutateAsync({
          ...availAddress,
          method: 'Avail Inventory',
          inventory_variant_id: v.inventory_variant.id,
        }),
    });
  };

  const onUpdateVariant = (a: Address) => {
    prompt({
      title: 'Update Kit SKU',
      description: 'Find the SKU of the kit you would like to change to.',
      fields: [
        FieldFactory.belongsTo('inventory_variant', 'inventoryVariants').withRequestParams({
          'filter[customer_id]': customerId,
        }),
      ],
      schema: z.object({
        inventory_variant: genericModelReferenceSchema,
      }),
      initialValues: { inventory_variant: a.inventory_variant || undefined },
      onSubmit: (v) =>
        updateRequest.mutateAsync({
          ...a,
          inventory_variant_id: v.inventory_variant.id,
        }),
    });
  };

  const onDeleteVariant = (a: Address) => {
    confirm({
      title: 'Delete Kit SKU',
      description:
        'Are you sure you want to delete this kit SKU? All associated kits will also be deleted.',
    }).then(() => deleteRequest.mutateAsync(a.id));
  };

  return (
    <Card>
      <CardHeader
        title="Kit SKUs"
        action={
          <Can permission="write:orders">
            <IconButton onClick={onAddVariant} aria-label="Add SKU">
              <AddBoxIcon />
            </IconButton>
          </Can>
        }
      />
      {isFetching && <LinearProgress />}

      <List>
        {addresses.map((a) => (
          <ListItemButton key={a.id} onClick={() => onSelect(a)}>
            {a.inventory_variant ? (
              <SkuLabel variant={a.inventory_variant} />
            ) : (
              <>
                <ListItemIcon>
                  <WarningIcon color="warning" />
                </ListItemIcon>
                <ListItemText>Unknown SKU</ListItemText>
              </>
            )}
            <Box ml="auto">
              <SubMenu
                items={[
                  new ButtonAction('Edit', () => onUpdateVariant(a)).withIcon(EditIcon),
                  new ButtonAction('Delete', () => onDeleteVariant(a)).withIcon(DeleteIcon),
                ]}
              />
            </Box>
          </ListItemButton>
        ))}
      </List>
    </Card>
  );
}
