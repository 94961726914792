import { Print } from '@mui/icons-material';
import { ButtonAction, FieldFactory, Resource } from '@/classes';
import InventoryPickPage from '@/components/Inventory/InventoryPickPage';
import { PICK_READY_COLORS, PICK_STATUS_COLORS } from '@/constants';
import { AppResponse, InventoryPick } from '@/types';
import getApiUrl from '@/utils/getApiUrl';

export default function inventoryPicks(appConfig: AppResponse) {
  const giftNotePrintableId = appConfig.printables.find(
    (p) => p.template === 'GiftNoteTemplate',
  )?.id;

  return new Resource<InventoryPick>('Inventory Picks')
    .withRelations(['items'])
    .setReadOnly(true)
    .getLabelUsing((v) => v.number)
    .getTitleUsing((v) => v.number)
    .withDefaultSort('ordered_at')
    .withBulkActions([
      new ButtonAction(
        'Print',
        ({ selected }) =>
          window.open(
            getApiUrl(`/api/printables/${giftNotePrintableId}/render/${selected.join(',')}`),
          ),
        Print,
      ),
    ])
    .withDefaultFilters({
      bucket: 'to_pick',
    })
    .withFilters([
      FieldFactory.belongsTo('location_group', 'inventoryLocationGroups')
        .withFilterKey('filter[location_group_id]')
        .asQuickFilter(),
      FieldFactory.radio('bucket', {
        to_pick: 'To Pick',
        to_ship: 'To Ship',
        to_ship_today: 'To Ship Today',
        has_note: 'Has Note',
        all: 'All',
      }).asQuickFilter(),
    ])
    .withColumns([
      FieldFactory.text('id').withLabel('#').sortable(),
      FieldFactory.text('number').sortable(),
      FieldFactory.timestamp('ordered_at').sortable().withLabel('Order Date'),
      FieldFactory.text('pickable')
        .withLabel('Order')
        .renderCellUsing((v) => v.increment_id || v.number),
      FieldFactory.belongsTo('location_group', 'inventoryLocationGroups').sortable(
        'location_group.name',
      ),
      FieldFactory.status('status', PICK_STATUS_COLORS).filterable(),
      FieldFactory.status('ready_status', PICK_READY_COLORS),
    ])
    .editUsing(InventoryPickPage);
}
