import { Dispatch, SetStateAction } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { z } from 'zod';
import { useUpdateDesignColors } from '@/api/designs';
import FieldFactory from '@/classes/FieldFactory';
import DesignColorRow, { FlexibleControl } from '@/components/Designs/DesignColorRow';
import Form from '@/components/Form/Form';
import FormField from '@/components/Form/FormField';
import { Customer, Design, designColorPayloadSchema, Order } from '@/types';
import { useZodForm } from '@/utils/form';
import { useRecord } from '@/utils/genericResource';

const formSchema = z.object({
  colors: z.array(designColorPayloadSchema),
  has_underbase: z.boolean(),
});

export type DesignColorFormValues = z.infer<typeof formSchema>;

export default function ProductionDesignColorsDialogForm({
  customer,
  freeSolo,
  design,
  setOpen,
}: {
  customer?: Customer;
  freeSolo?: boolean;
  design: Design;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { id: orderId } = useRecord<Order>();
  const updateOrderDesignColors = useUpdateDesignColors(design, () => setOpen(false), orderId);
  const form = useZodForm(formSchema, {
    colors: design.colors,
    has_underbase: design.has_underbase,
  });

  const { fields, move } = useFieldArray({
    control: form.control,
    name: 'colors',
  });

  const onSubmit = (data: DesignColorFormValues) => {
    return updateOrderDesignColors.mutateAsync(data);
  };

  const onSortEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return;

    const oldIndex = fields.findIndex((c) => c.id === active.id);
    const newIndex = fields.findIndex((c) => c.id === over.id);
    move(oldIndex, newIndex);
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      <DialogContent sx={{ pt: 0 }}>
        <Stack spacing={2}>
          <FormField field={FieldFactory.boolean('has_underbase')} />

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Code</TableCell>
                  <TableCell style={{ minWidth: 300 }}>Color</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Mesh</TableCell>
                  <TableCell>Squeegee</TableCell>
                  <TableCell>Flash</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <DndContext onDragEnd={onSortEnd}>
                  <SortableContext items={fields.map((c) => c.id)}>
                    {fields.map((field, idx) => (
                      <DesignColorRow
                        key={field.id}
                        id={`${field.id}`}
                        index={idx}
                        decorationUnit={'colors'}
                        customer={customer}
                        freeSolo={freeSolo}
                        control={form.control as unknown as FlexibleControl}
                        shouldDisable
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
        <LoadingButton type="submit" variant="contained" loading={form.formState.isSubmitting}>
          Update
        </LoadingButton>
      </DialogActions>
    </Form>
  );
}
