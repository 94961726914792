import { ArrowForward } from '@mui/icons-material';
import { Alert, Box, Grid2 as Grid, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import startCase from 'lodash/startCase';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { FieldFactory } from '@/classes';
import OrderInventoryMapping from '@/components/Inventory/OrderInventoryMapping';
import OrderKitting from '@/components/Kitting/OrderKitting';
import OrderShipmentsCard from '@/components/Shipping/OrderShipmentsCard';
import TextButton from '@/components/Text/TextButton';
import { SHIPPING_MODE_OPTIONS } from '@/constants';
import { useDialogs } from '@/contexts/DialogContext';
import { Order, ShippingMode, shippingModeSchema } from '@/types';
import { useOnReloadRecord, useRecord } from '@/utils/genericResource';
import OrderShippingAddresses from './OrderShippingAddresses';

function ShippingModePreview() {
  const form = useFormContext<{ shipping_mode: ShippingMode }>();
  const shippingMode = form.watch('shipping_mode');

  if (shippingMode === 'kits') {
    return (
      <Alert severity="info">
        This shipping mode allows items from this order and/or other production orders to be kitted
        and split shipped to mulitple addresses.
      </Alert>
    );
  }
  if (shippingMode === 'inventory') {
    return (
      <Alert severity="info">
        This shipping mode specifies that the items in the order be added to inventory. In the case
        of decorated inventory, you will be able to specify how the items in the order map to SKUs
        in Avail inventory.
      </Alert>
    );
  }

  if (shippingMode === 'inventory_kit') {
    return (
      <Alert severity="info">
        This shipping mode involves a packout but instead of shipping to an address, the kits will
        be added to inventory. This is useful for creating inventory kits from individual items in
        various orders.
      </Alert>
    );
  }

  return null;
}

export default function OrderShippingTab() {
  const order = useRecord<Order>();
  const { prompt } = useDialogs();
  const onReload = useOnReloadRecord();

  const onShippingMode = () => {
    prompt({
      title: 'Update Shipping Mode',
      fields: [
        FieldFactory.radio('shipping_mode', SHIPPING_MODE_OPTIONS).withoutLabel(),
        FieldFactory.custom('preview', ShippingModePreview),
      ],
      schema: z.object({
        shipping_mode: shippingModeSchema,
      }),
      initialValues: { shipping_mode: order.shipping_mode },
      onSubmit: (v) => axios.put(`/api/orders/${order.id}/shipping-mode`, v),
    }).then(() => {
      onReload();
    });
  };

  return (
    <div>
      <Box mb={2} textAlign="right">
        <Typography>
          Shipping Mode:{' '}
          <TextButton disabled={!!order.invoiced_at} onClick={onShippingMode}>
            {startCase(order.shipping_mode)}
          </TextButton>
        </Typography>
      </Box>
      {order.shipping_mode === 'inventory' ? (
        <OrderInventoryMapping />
      ) : order.shipping_mode === 'inventory_kit' ? (
        <OrderKitting forInventory />
      ) : order.shipping_mode === 'kits' ? (
        <OrderKitting />
      ) : (
        <>
          {order.kitting_order_id && (
            <Alert
              severity="info"
              sx={{ mb: 1 }}
              action={
                <IconButton
                  component={Link}
                  size="small"
                  to={`/orders/${order.kitting_order_id}?tab=shipping&prodOrder=${order.id}`}
                >
                  <ArrowForward />
                </IconButton>
              }
            >
              This order is part of a kitting order. You can assign the shippable items on this
              order to kits in that order.
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, lg: 5 }}>
              <OrderShippingAddresses />
            </Grid>
            <Grid size={{ xs: 12, lg: 7 }}>
              <OrderShipmentsCard showActions={false} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
