import { ChangeEvent, SyntheticEvent, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import get from 'lodash/get';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import DrawerButtons from '@/components/Form/DrawerButtons';
import DrawerFormSection from '@/components/Form/DrawerFormSection';
import Label from '@/components/Shared/Label';
import ChooseAccount from '@/components/Shipping/ChooseAccount';
import { useConfig } from '@/contexts/AppConfigContext';
import { Parcel, CustomerThirdPartyShippingAccount } from '@/types';
import { ShipmentBatch } from '@/types';

export default function CreateBatchShipment({
  onBatch,
  orderId,
  customerId,
  onDiscard,
}: {
  onBatch: (b: ShipmentBatch) => void;
  orderId: number;
  customerId: number;
  onDiscard?: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [dimensions, setDimensions] = useState<Parcel>({
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  });
  const [accountId, setAccountId] = useState<string>();
  const [serviceLevel, setServiceLevel] = useState<string>();
  const [thirdPartyAccountId, setThirdPartyAccountId] = useState<number | null>(null);
  const [poBox, setPoBox] = useState<'all' | '1' | '0'>('all');
  const { shippoCarrierAccounts } = useConfig();
  const selectedAccount = shippoCarrierAccounts.find((ca) => ca.id === accountId);
  const serviceLevels = get(selectedAccount, 'service_levels', {});
  const booleanPoBox = poBox === 'all' ? null : poBox === '1';

  const { data: thirdPartyAccounts = [] } = useQuery(
    ['thirdPartyShippingAccounts', customerId],
    () =>
      axios
        .get<{
          data: CustomerThirdPartyShippingAccount[];
        }>(`/api/customers/${customerId}/third-party-shipping-accounts`)
        .then(({ data }) => data.data),
  );

  const { data: previewCount, isLoading: isLoadingPreview } = useQuery(
    ['previewCount', orderId, booleanPoBox],
    () =>
      axios
        .post(`/api/orders/${orderId}/shipment-batches/preview`, { po_box: booleanPoBox })
        .then(({ data }) => data.count),
  );

  const handleChange = (field: keyof Parcel) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDimensions((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const submit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setSubmitting(true);
    const payload = pickBy({
      ...dimensions,
      third_party_account_id: thirdPartyAccountId,
      carrier_account_id: accountId,
      service_level: serviceLevel,
    });
    axios
      .post<ShipmentBatch>(`/api/orders/${orderId}/shipment-batches`, {
        ...payload,
        po_box: booleanPoBox,
      })
      .then(({ data }) => {
        onBatch(data);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      {onDiscard && (
        <div style={{ float: 'right' }}>
          <Button size="small" onClick={onDiscard}>
            Back to List
          </Button>
        </div>
      )}
      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
        Create Batch
      </Typography>
      <form onSubmit={submit}>
        <DrawerFormSection title="Filter Kits">
          <FormControl fullWidth>
            <InputLabel>Include PO Boxes</InputLabel>
            <Select
              label="Include PO Boxes"
              value={poBox}
              onChange={(e) => {
                setPoBox(e.target.value as typeof poBox);
              }}
            >
              <MenuItem value="all">Yes</MenuItem>
              <MenuItem value="1">Only PO Boxes</MenuItem>
              <MenuItem value="0">No</MenuItem>
            </Select>
          </FormControl>

          <Box mt={1} display="flex" alignItems="center">
            <Box mr={1}>Kits to send:</Box>
            {isLoadingPreview ? <CircularProgress size={16} /> : <Label>{previewCount}</Label>}
          </Box>
        </DrawerFormSection>

        <DrawerFormSection title="Carrier">
          <Box mb={1}>
            <FormControl fullWidth>
              <InputLabel>Carrier Account</InputLabel>
              <Select
                id="carrier-account"
                value={accountId}
                onChange={(e) => setAccountId(e.target.value)}
                label="Carrier Account"
                required
              >
                {shippoCarrierAccounts.map((a) => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {accountId && (
            <Box mb={1}>
              <FormControl fullWidth>
                <InputLabel>Service Level</InputLabel>
                <Select
                  id="service-level"
                  value={serviceLevel}
                  onChange={(e) => setServiceLevel(e.target.value)}
                  label="Service Level"
                  required
                >
                  {map(serviceLevels, (name, code) => (
                    <MenuItem value={code} key={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <ChooseAccount
            accountId={thirdPartyAccountId}
            onChange={setThirdPartyAccountId}
            accounts={thirdPartyAccounts}
            carrier={selectedAccount?.carrier}
          />
        </DrawerFormSection>

        <DrawerFormSection title="Dimensions">
          <Box mx={-1}>
            <TextField
              variant="outlined"
              size="small"
              label="Length"
              value={dimensions.length}
              sx={{ m: 1 }}
              onChange={handleChange('length')}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label="Height"
              value={dimensions.height}
              sx={{ m: 1 }}
              onChange={handleChange('height')}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label="Width"
              value={dimensions.width}
              sx={{ m: 1 }}
              onChange={handleChange('width')}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              required
            />
            <TextField
              variant="outlined"
              size="small"
              label="Weight"
              value={dimensions.weight}
              sx={{ m: 1 }}
              onChange={handleChange('weight')}
              type="number"
              inputProps={{
                step: 0.01,
              }}
              required
            />
          </Box>
        </DrawerFormSection>

        <DrawerButtons>
          <LoadingButton
            loading={submitting}
            disabled={previewCount === 0}
            type="submit"
            variant="contained"
          >
            Submit
          </LoadingButton>
        </DrawerButtons>
      </form>
    </div>
  );
}
