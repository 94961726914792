import { useState } from 'react';
import LockIcon from '@mui/icons-material/Lock';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  ButtonBase,
  DialogActions,
} from '@mui/material';
import { useDesignColorUnlock } from '@/api/designs';
import { useConfig } from '@/contexts/AppConfigContext';
import { Design } from '@/types';

export default function ProductionDesignLockedPill({
  disabled,
  design,
  orderId,
}: {
  disabled?: boolean;
  design: Design;
  orderId?: number;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const { permissions } = useConfig();
  const unlockOrderDesignColor = useDesignColorUnlock(design, () => setOpen(false), orderId);

  return (
    <>
      <ButtonBase
        onClick={() => setOpen(true)}
        disabled={disabled || !permissions.includes('production_events:admin')}
      >
        <Chip
          color={'error'}
          label={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <LockIcon sx={{ mr: 1 }} />
              Locked
            </span>
          }
          size="medium"
        />
      </ButtonBase>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>Unlock Design</DialogTitle>
        <DialogContent sx={{ pb: 2 }}>Are you sure you want to unlock this design?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton
            loading={unlockOrderDesignColor.isLoading}
            onClick={() => unlockOrderDesignColor.mutate()}
            variant="contained"
          >
            Unlock
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
