import { Chip, Tooltip } from '@mui/material';
import { FieldProps } from '@/classes/types';

export default function GenerateSkusBadge(props: FieldProps) {
  if (props.field.value) {
    return (
      <Tooltip title="SKUs are being managed by Avail for this product. If you add a new variant, Avail will generate a SKU">
        <Chip
          color="success"
          label="Auto-Generated SKUs"
          sx={{ transform: 'translate(1rem, -1rem)' }}
        />
      </Tooltip>
    );
  }

  return null;
}
