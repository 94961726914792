import { Chip, List, ListItem, ListItemText } from '@mui/material';
import { PurchaseOrderTrackingItem } from '@/types';

export default function PurchaseOrderTrackingItems({
  items,
}: {
  items: PurchaseOrderTrackingItem[];
}) {
  return (
    <List>
      {items.map((i) => (
        <ListItem key={i.id} dense>
          <ListItemText
            primary={i.mpn}
            secondary={i.mpn !== i.style_number && i.style_number && `Style: ${i.style_number}`}
          />
          <ListItemText sx={{ ml: 'auto', flexGrow: 0 }}>
            <Chip label={i.qty} size="small" />
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
