import { Alert, Grid2 as Grid, Stack } from '@mui/material';
import KitsTable from '@/components/Kitting/KitsTable';
import OrderSummaryCard from '@/components/Orders/OrderSummaryCard';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { useRecord } from '@/utils/genericResource';
import NoteViewer from '../Notes/NoteViewer';
import OrderShippingInventory from './OrderShippingInventory';
import OrderShippingSimple from './OrderShippingSimple';

export default function ShippingForOrder() {
  const order = useRecord('ordersToShip');

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, lg: 9 }}>
        <Stack spacing={2}>
          <NoteViewer resource="orders" resourceId={order.id} tag="shipping" />

          {order.shipping_mode === 'bulk' ? (
            <OrderShippingSimple orderId={order.id} customerId={order.customer.id} />
          ) : order.shipping_mode === 'kits' || order.shipping_mode === 'inventory_kit' ? (
            <KitsTable
              orderId={order.id}
              customerId={order.customer.id}
              forInventory={order.shipping_mode === 'inventory_kit'}
            />
          ) : order.shipping_mode === 'inventory' ? (
            <OrderShippingInventory order={order} />
          ) : (
            <Alert severity="error">Unknown shipping mode: {order.shipping_mode}</Alert>
          )}

          <ShipmentsCard shippableId={order.id} shippableType="order" />
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, lg: 3 }}>
        <OrderSummaryCard order={order} />
      </Grid>
    </Grid>
  );
}
