import { useState } from 'react';
import { Calculate, Search } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useGetShippableItems, useUpdateShippableOrderItem } from '@/api/orderItems';
import '@/api/shipping';
import { FieldFactory } from '@/classes';
import InventoryConversionPill from '@/components/Inventory/InventoryConversionPill';
import OrderItemProduct from '@/components/Orders/Items/OrderItemProduct';
import PrintMenu from '@/components/Print/PrintMenu';
import ProductFinderDialog from '@/components/Products/ProductFinderDialog';
import SkuLabel from '@/components/Products/SkuLabel';
import { useDialogs } from '@/contexts/DialogContext';
import { Order, OrderItem, orderItemPayloadSchema, ProductVariant } from '@/types';
import { useRecord } from '@/utils/genericResource';

export default function OrderInventoryMapping() {
  const order = useRecord<Order>();
  const orderId = order.id;
  const [choosing, setChoosing] = useState<OrderItem>();
  const { prompt } = useDialogs();

  const { data: items = [], isLoading } = useGetShippableItems(orderId);
  const updateOrderItemRequest = useUpdateShippableOrderItem(orderId);

  const onVariantChosen = (variant: ProductVariant) => {
    if (choosing) {
      updateOrderItemRequest.mutateAsync({ id: choosing.id, inventory_variant: variant });
      setChoosing(undefined);
    }
  };

  const onUpdateUnits = (item: OrderItem) => {
    prompt({
      title: 'Update Unit Conversion',
      description: (
        <Typography variant="body2" color="textSecondary">
          When adding items to inventory, you can optionally convert the units to a different unit
          type. For example, if you ordered 2 cases of 12 bottles from the vendor, you can set this
          value to 12 to individually add each of the 24 bottles to inventory.
        </Typography>
      ),
      fields: [
        FieldFactory.number('inventory_unit_conversion')
          .withInputProps({
            step: 0.01,
          })
          .withLabel('Unit Conversion')
          .withSize('medium'),
      ],
      schema: orderItemPayloadSchema.pick({ inventory_unit_conversion: true }),
      initialValues: {
        inventory_unit_conversion: item.inventory_unit_conversion,
      },
      onSubmit: (v) => updateOrderItemRequest.mutateAsync({ id: item.id, ...v }),
    });
  };

  return (
    <Card>
      <CardHeader title="Item to SKU Mapping" />
      <CardContent>
        When items are ready to ship, they will be added to inventory using this mapping.
      </CardContent>

      {isLoading ? (
        <LinearProgress />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>SKU Mapping</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>
                  <OrderItemProduct item={item} showSize />
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <div>{item.qty}</div>
                    <InventoryConversionPill item={item} />
                  </Box>
                </TableCell>
                <TableCell>
                  {item.inventory_variant ? (
                    <SkuLabel variant={item.inventory_variant} />
                  ) : (
                    <Alert severity="warning" sx={{ maxWidth: 300 }}>
                      No SKU Mapped
                    </Alert>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title="Find SKU">
                    <IconButton onClick={() => setChoosing(item)}>
                      <Search />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add Unit Conversion">
                    <IconButton onClick={() => onUpdateUnits(item)}>
                      <Calculate />
                    </IconButton>
                  </Tooltip>
                  {item.inventory_variant ? (
                    <PrintMenu model="variant" id={item.inventory_variant.id} />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <ProductFinderDialog
        open={!!choosing}
        mode="variant"
        onSuccess={onVariantChosen}
        onCancel={() => setChoosing(undefined)}
        isLoading={updateOrderItemRequest.isLoading}
        customerId={order.customer.id}
        defaultFacets={{ customer: order.customer.name }}
      />
    </Card>
  );
}
