import { useCallback, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import {
  ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import startCase from 'lodash/startCase';
import { useNavigate } from 'react-router-dom';
import ReactTable from '@/components/DataTable/ReactTable';
import RateOfChangeChip from '@/components/Reports/RateOfChangePill';
import { ReportResultsRow, ReportTypeProps } from '@/types';
import formatNumber from '@/utils/formatNumber';
import { getCompareToRow, getReportColumns } from '@/utils/reports';

export default function ReportTable({
  results,
  compareToResults,
  compareTo,
  columns,
}: ReportTypeProps) {
  const columnSettings = useMemo(() => columns || {}, [columns]);

  const navigate = useNavigate();

  const formatValueForCell = useCallback(
    (key: string, value: string | number | null) => {
      const format = columnSettings[key]?.format;
      if (value == null) {
        return '-';
      }
      if (format) {
        return formatNumber(value, format);
      }
      return value;
    },
    [columnSettings],
  );

  const onRowClick = (row: ReportResultsRow) => {
    if ('href' in row && typeof row.href === 'string') {
      navigate(row.href);
    }
  };

  const tableColumns: ColumnDef<ReportResultsRow>[] = useMemo(
    () =>
      getReportColumns(results).map((c) => ({
        header: startCase(c),
        accessorKey: c,
        meta: {
          aggregatable: Boolean(columns?.[c]?.format),
        },
        cell: ({ row, getValue, column }) => {
          const cellValue = getValue<string | number | null>();
          const compareToRow = getCompareToRow(row.original, compareToResults);
          const compareValue = compareToRow?.[c];
          const contents = formatValueForCell(column.id, cellValue);
          if (compareTo && typeof cellValue === 'number' && typeof compareValue === 'number') {
            return (
              <Tooltip
                title={
                  <RateOfChangeChip
                    number={cellValue}
                    compareTo={compareValue}
                    compareToType={compareTo}
                    format={columns?.[c]?.format || 'number'}
                  />
                }
              >
                <span>{contents}</span>
              </Tooltip>
            );
          }
          return contents;
        },
      })),
    [results, compareToResults, formatValueForCell],
  );

  const table = useReactTable({
    data: results,
    columns: tableColumns,
    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  return <ReactTable table={table} onRowClick={onRowClick} enableToolbar={false} size="small" />;
}
