import { Stack } from '@mui/material';
import KitsTable from '@/components/Kitting/KitsTable';
import NoteViewer from '@/components/Notes/NoteViewer';
import ShipmentsCard from '@/components/Shipping/ShipmentsCard';
import { useRecord } from '@/utils/genericResource';

export default function PackoutShippingStatus() {
  const { order } = useRecord('orderPackouts', true);

  return (
    <Stack spacing={2}>
      <NoteViewer tag="shipping" resource="orders" resourceId={order.id} />
      <KitsTable
        orderId={order.id}
        customerId={order.customer_id}
        forInventory={order.shipping_mode === 'inventory_kit'}
      />
      <ShipmentsCard shippableId={order.id} shippableType="order" />
    </Stack>
  );
}
