import { FieldFactory, Resource } from '@/classes';
import { customerThirdPartyAccountPayloadSchema, CustomerThirdPartyShippingAccount } from '@/types';

export default function thirdPartyShippingAccounts() {
  return new Resource<CustomerThirdPartyShippingAccount>('Third Party Shipping Accounts')
    .withSchema(customerThirdPartyAccountPayloadSchema)
    .setUseDrawer()
    .withFieldsAndColumns([
      FieldFactory.text('name').sortable(),
      FieldFactory.select('carrier', {
        ups: 'UPS',
        fedex: 'FedEx',
      }).setRequired(),
      FieldFactory.text('account_number'),
      FieldFactory.text('zip').withHelp(
        'ZIP code of account number to be billed (required for UPS if there is a zip on the billing account).',
      ),
      FieldFactory.text('country').withHelp(
        'Country iso2 code of account number to be billed (required for UPS third party billing only).',
      ),
    ]);
}
