import axios from 'axios';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import { FieldFactory, StackLayout } from '@/classes';
import { TotalLabel, TotalTable, TotalValue } from '@/components/Shared/Label';
import TextButton from '@/components/Text/TextButton';
import { useDialogs } from '@/contexts/DialogContext';
import { useHasPermission } from '@/hooks/permissions';
import { PurchaseOrder, purchaseOrderPayloadSchema } from '@/types';
import curr from '@/utils/curr';

export default function PurchaseOrderTotals({
  purchaseOrder,
  onUpdated,
}: {
  purchaseOrder: PurchaseOrder;
  onUpdated: (p: Partial<PurchaseOrder>) => void;
}) {
  const { prompt } = useDialogs();
  const hasPermission = useHasPermission();
  const {
    shipping,
    tax,
    discount,
    fee: cachedFee,
    fee_percent: feePercent,
    subtotal: cachedSubtotal,
    total: cachedTotal,
    id,
  } = purchaseOrder;

  const onEdit = (field: 'shipping' | 'tax') => () => {
    prompt({
      title: `Edit ${startCase(field)}`,
      fields: [FieldFactory.curr(field).withSize('medium')],
      initialValues: pick(purchaseOrder, field),
      schema: purchaseOrderPayloadSchema.pick({ shipping: true, tax: true }).partial(),
      onSubmit: (v) => axios.put(`/api/purchase-orders/${id}`, v),
    }).then(({ data }) => {
      onUpdated(pick(data, ['shipping', 'tax', 'discount', 'fee', 'subtotal', 'total']));
    });
  };

  const onEditFee = () => {
    prompt({
      title: 'Edit Vendor Fee',
      fields: [
        new StackLayout('', [
          FieldFactory.useDefault('fee_override', FieldFactory.curr('fee_override')).with({
            useDefaultLabel: `Use Default Fee: ${feePercent || 0}%`,
          }),
          FieldFactory.boolean('fee_is_sports_inc', 'Is Sports Inc Upcharge'),
        ]).withSpacing(0),
      ],
      schema: purchaseOrderPayloadSchema.pick({ fee_override: true, fee_is_sports_inc: true }),
      initialValues: {
        fee_override: purchaseOrder.fee_override,
        fee_is_sports_inc: purchaseOrder.fee_is_sports_inc,
      },
      onSubmit: (v) => axios.put(`/api/purchase-orders/${id}`, v),
    }).then(({ data }) => {
      onUpdated(pick(data, ['fee', 'fee_override', 'fee_is_sports_inc', 'total']));
    });
  };

  const onEditDiscount = () => {
    prompt({
      title: 'Edit Discount',
      fields: [
        FieldFactory.curr('discount').withSize('medium'),
        FieldFactory.belongsTo('discount_gl_account', 'accounts'),
        FieldFactory.boolean('fee_include_discount', 'Include discount in fee calculation'),
      ],
      schema: purchaseOrderPayloadSchema.pick({
        discount: true,
        discount_gl_account: true,
        fee_include_discount: true,
      }),
      initialValues: pick(purchaseOrder, [
        'discount',
        'fee_include_discount',
        'discount_gl_account',
      ]),
      onSubmit: (v) => axios.put<PurchaseOrder>(`/api/purchase-orders/${id}`, v),
    }).then(({ data }) => {
      onUpdated(pick(data, ['discount', 'fee_include_discount', 'discount_gl_account', 'total']));
    });
  };

  return (
    <TotalTable>
      <tbody>
        <tr>
          <TotalLabel>Subtotal</TotalLabel>
          <TotalValue>{curr(cachedSubtotal)}</TotalValue>
        </tr>
        <tr>
          <TotalLabel>Shipping</TotalLabel>
          <TotalValue>
            <TextButton
              onClick={onEdit('shipping')}
              disabled={!hasPermission('write:purchase_orders')}
            >
              {curr(shipping || 0)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Fees</TotalLabel>
          <TotalValue>
            <TextButton onClick={onEditFee} disabled={!hasPermission('write:purchase_orders')}>
              {curr(cachedFee)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Tax</TotalLabel>
          <TotalValue>
            <TextButton onClick={onEdit('tax')} disabled={!hasPermission('write:purchase_orders')}>
              {curr(tax || 0)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Discount</TotalLabel>
          <TotalValue>
            <TextButton onClick={onEditDiscount} disabled={!hasPermission('write:purchase_orders')}>
              {curr(discount)}
            </TextButton>
          </TotalValue>
        </tr>
        <tr>
          <TotalLabel>Total</TotalLabel>
          <TotalValue>{curr(cachedTotal)}</TotalValue>
        </tr>
      </tbody>
    </TotalTable>
  );
}
