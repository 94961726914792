import { Chip, Tooltip } from '@mui/material';
import { OrderItem } from '@/types';
import numString from '@/utils/numString';

export default function InventoryConversionPill({
  item,
}: {
  item: Pick<OrderItem, 'inventory_unit_conversion' | 'qty'>;
}) {
  const conversion = item.inventory_unit_conversion ?? 1;
  return conversion !== 1 ? (
    <Tooltip
      title={`These items will be ${conversion < 1 ? 'combined' : 'split'} and ${numString(Math.floor(item.qty * conversion))} will be put into inventory.`}
    >
      <Chip label={`x ${conversion}`} />
    </Tooltip>
  ) : null;
}
