import { Block, CheckCircle, Help, LocalShipping } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { VendorInventoryCheckResponse, VendorInventoryCheckSummary } from '@/types';

const getIconForInventoryRow = (inv: VendorInventoryCheckSummary) => {
  if (inv.status === 'instock') {
    return <CheckCircle style={{ color: green[500] }} />;
  }
  if (inv.status === 'backordered') {
    return <LocalShipping style={{ color: orange[400] }} />;
  }
  if (inv.status === 'unknown') {
    return <Help style={{ color: grey[600] }} />;
  }
  return <Block style={{ color: red[500] }} />;
};

export default function InventoryCheckIcon({
  inventory,
  mpn,
}: {
  inventory?: VendorInventoryCheckResponse;
  mpn?: string | null;
}) {
  if (inventory && mpn && inventory[mpn]) {
    const copyToClipboard = () => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(inventory[mpn].message);
      }
    };

    return (
      <Tooltip title={inventory[mpn].message}>
        <IconButton size="small" onClick={copyToClipboard}>
          {getIconForInventoryRow(inventory[mpn])}
        </IconButton>
      </Tooltip>
    );
  }

  return null;
}
