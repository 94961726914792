import { useEffect } from 'react';
import { ArrowForward } from '@mui/icons-material';
import PlusIcon from '@mui/icons-material/Add';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import axios from 'axios';
import sumBy from 'lodash/sumBy';
import { Link, useNavigate } from 'react-router-dom';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { z } from 'zod';
import { useKitProductionOrders } from '@/api/kitting';
import { ButtonAction, FieldFactory } from '@/classes';
import OrderStatus from '@/components/Orders/OrderStatus';
import SubMenu from '@/components/Shared/SubMenu';
import { useDialogs, useShowLoading } from '@/contexts/DialogContext';
import { Kit, Address } from '@/types';
import { useRecord } from '@/utils/genericResource';
import ProductionOrderItem from './ProductionOrderItem';

export default function OrderProductionOrders({
  kit,
  address,
}: {
  kit: Kit | undefined;
  address: Address | undefined;
}) {
  const order = useRecord('orders');
  const { prompt, confirm } = useDialogs();
  const [fromOrder, setFromOrder] = useQueryParam('prodOrder', withDefault(NumberParam, order.id));
  const showLoading = useShowLoading();
  const navigate = useNavigate();

  const {
    data: productionOrders = [],
    isFetching,
    isLoading,
    refetch,
  } = useKitProductionOrders(order.id);
  const selectedOrder = productionOrders.find((o) => o.id === fromOrder);
  const someAssigned = selectedOrder?.shippable_items.some((i) => Number(i.qty_assigned) > 0);

  useEffect(() => {
    if (!selectedOrder) {
      setFromOrder(productionOrders[0]?.id);
    }
  }, [selectedOrder, productionOrders]);

  const onBackorder = () => {
    prompt({
      title: `Move Production Order(s) to Kit Backorder`,
      description: (
        <Stack spacing={1}>
          <Typography variant="body2">
            This will create a new kitting order, and move the following production order(s) to it.
          </Typography>
          <Alert severity="info">
            If you want to move specific items from a production order, please create a backorder
            for those items and then move that backorder to a new kitting order.
          </Alert>
        </Stack>
      ),
      initialValues: {
        production_order_ids: [selectedOrder!.id],
        copy: true,
      },
      schema: z.object({
        production_order_ids: z.array(z.number()).min(1),
        copy: z.boolean(),
      }),
      fields: [
        FieldFactory.select(
          'production_order_ids',
          productionOrders.map((o) => ({
            value: o.id,
            label: <ListItemText primary={o.increment_id} secondary={o.description} />,
          })),
        ).with({ multiple: true, label: 'Production Orders to Move' }),
        FieldFactory.boolean('copy', 'Keep original kit items and mark as backordered'),
      ],
      onSubmit: (v) => axios.post(`/api/orders/${order.id}/kit-backorder`, v),
    }).then(({ data }) => {
      navigate(`/orders/${data.id}?tab=shipping`);
    });
  };

  const onAdd = () => {
    prompt({
      title: 'Add Production Order',
      fields: [
        FieldFactory.belongsTo('order', 'orders').withRequestParams({
          'filter[customer_id]': order.customer_id,
          'filter[kitting_order_id][null]': '1',
        }),
      ],
      schema: z.object({
        order: z.object({
          id: z.number(),
        }),
      }),
      onSubmit: (v) =>
        axios
          .post(`/api/orders/${order.id}/production-orders/${v.order.id}`)
          .then(() => refetch())
          .then(() => {
            setFromOrder(v.order.id);
          }),
    });
  };

  const onRemove = () => {
    confirm({
      title: 'Remove Production Order',
      description: `This will remove ${selectedOrder!.increment_id} as a production order for this kiting order.`,
    }).then(() => {
      showLoading(
        axios
          .delete(`/api/orders/${order.id}/production-orders/${selectedOrder!.id}`)
          .then(() => refetch()),
      );
    });
  };

  return (
    <Card>
      <CardHeader title="Production Orders" />
      {isFetching && <LinearProgress />}
      <CardContent>
        {productionOrders.length > 0 && (
          <Box display="flex" gap={1} alignItems="center">
            <FormControl fullWidth>
              <Select
                variant="outlined"
                value={fromOrder}
                onChange={(e) => setFromOrder(Number(e.target.value))}
                size="small"
              >
                {productionOrders.map((order) => (
                  <MenuItem key={order.id} value={order.id}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ListItemText primary={order.increment_id} secondary={order.description} />
                      <OrderStatus status={order.status} size="small" />
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedOrder && (
              <IconButton component={Link} to={`/orders/${selectedOrder.id}?tab=items`}>
                <ArrowForward />
              </IconButton>
            )}
          </Box>
        )}
        {productionOrders.length === 0 && !isLoading && (
          <Typography color="textSecondary" variant="body2">
            No production orders are attached to this kitting order.
          </Typography>
        )}
      </CardContent>
      {selectedOrder && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Alloc</TableCell>
                <TableCell padding="checkbox" />
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedOrder.shippable_items.map((i) => (
                <ProductionOrderItem
                  key={i.id}
                  item={i}
                  orderId={order.id}
                  selectedKit={kit}
                  selectedAddress={address}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {sumBy(selectedOrder.shippable_items, (i) => i.qty_assigned!)} /{' '}
                  {sumBy(selectedOrder.shippable_items, (i) => i.qty_assignable!)}
                </TableCell>
                <TableCell padding="checkbox" />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button color="secondary" onClick={onAdd}>
          <PlusIcon sx={{ mr: 1 }} fontSize="small" /> Add Production Order
        </Button>

        {selectedOrder && selectedOrder.id != order.id && (
          <SubMenu
            items={[
              new ButtonAction('Remove as Production Order', onRemove).setDisabled(someAssigned),
              new ButtonAction('Move to Kit Backorder', onBackorder).setDisabled(
                productionOrders.length === 1,
              ),
            ]}
          />
        )}
      </CardActions>
    </Card>
  );
}
