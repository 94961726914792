import { useState } from 'react';
import { Dialog, DialogTitle, Button, Tooltip } from '@mui/material';
import ProductionDesignColorsDialogForm from '@/components/Designs/ProductionDesignColorsDialogForm';
import ProductionDesignLockedPill from '@/components/Designs/ProductionDesignLockedPill';
import Can from '@/components/Permissions/Can';
import { Order, Design } from '@/types';
import { formatDate } from '@/utils/dates';
import { useRecord } from '@/utils/genericResource';

export default function ProductionDesignColorsDialog({ design }: { design: Design }) {
  const [open, setOpen] = useState<boolean>(false);
  const { id: orderId } = useRecord<Order>();
  const toolTipMessage = design.locked_at
    ? `Design Locked by ${design.locked_by_user ? design.locked_by_user.name : 'Unknown'} on ${formatDate(design.locked_at)}`
    : 'Edit Design Colors';

  return (
    <>
      <Can permission="write:production_events">
        <Tooltip title={toolTipMessage} placement="top">
          {design.locked_at ? (
            <ProductionDesignLockedPill design={design} orderId={orderId} />
          ) : (
            <Button onClick={() => setOpen(true)}>Edit</Button>
          )}
        </Tooltip>
      </Can>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>Edit Design Sequence</DialogTitle>
        <ProductionDesignColorsDialogForm design={design} setOpen={setOpen} />
      </Dialog>
    </>
  );
}
