import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CustomerThirdPartyShippingAccount } from '@/types';

export default function useThirdPartyAccountsForCustomer(
  customerId: number | undefined | boolean,
): CustomerThirdPartyShippingAccount[] {
  return (
    useQuery(
      ['thirdPartyShippingAccounts', customerId],
      () =>
        axios
          .get<{
            data: CustomerThirdPartyShippingAccount[];
          }>(`/api/customers/${customerId}/third-party-shipping-accounts`)
          .then(({ data }) => data.data),
      { enabled: Boolean(customerId) },
    ).data || []
  );
}
