import { Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ProductImage from '@/components/Shared/ProductImage';
import TextLink from '@/components/Text/TextLink';
import { useHasPermission } from '@/hooks/permissions';
import { SkuResource } from '@/types';

export default function SkuLabel({
  variant,
  withoutDescription,
  children,
}: {
  variant: SkuResource;
  withoutDescription?: boolean;
  children?: React.ReactNode;
}) {
  const hasPermission = useHasPermission();

  return (
    <Box display="flex" alignItems="center" gap={1.5} minWidth={0}>
      <ProductImage product={variant.product} />
      <Box display="flex" flexDirection="column" gap={0.25}>
        <Box display="flex" gap={1} whiteSpace="nowrap" alignItems="center">
          <TextLink
            disabled={!hasPermission('read:products')}
            to={`/inventory-variants/${variant.id}`}
          >
            {variant.sku}
          </TextLink>

          {variant.product.customer ? (
            <Chip label="Customer-Owned" size="small" variant="outlined" />
          ) : variant.product.business ? (
            <Chip label="LOB-Owned" size="small" variant="outlined" />
          ) : null}
        </Box>
        {!withoutDescription && (
          <Typography variant="body2" color="textSecondary">
            {variant.description}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );
}
